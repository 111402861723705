import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/Theme';
import { LocationInterface } from '../Location';

const CustomerTile = (props: LocationInterface): JSX.Element => {
    const { customerPin, hasTheme } = useContext(ThemeContext);
    if (hasTheme && customerPin) {
        return <img src={customerPin} alt="location" width="48" />;
    }

    return <div data-lat={props.lat} data-lng={props.lng} className="pin bounce" />;
};

export default CustomerTile;
