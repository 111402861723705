import React from 'react';
import './Button.scss';

export interface ButtonProps {
    show: boolean;
    onClickFunction: any;
    title: string;
    children?: React.ReactElement;
    classNames?: string[];
}

export const Button = (props: ButtonProps): JSX.Element | null => {
    const { onClickFunction, title, show, classNames } = props;

    if (!show) {
        return null;
    }

    return (
        <div onClick={onClickFunction} className={classNames ? `button-wrapper ${classNames.join(' ')}` : 'button-wrapper'}>
            <button type="button">
                {props.children}
                {title}
            </button>
        </div>
    );
};