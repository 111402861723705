import React from 'react';
import axios from 'axios';
import { apiDeliveryEndpoint } from '../utils/api';
import { CustomerInterface, DefaultCustomer } from './ICustomer';
import moment from 'moment';
import Anonymized from '../layouts/Anonymized';
import Status from './Status';
import { PackageInterface } from '../components/Customer/Packages/Package';
import { getPodTypeFromString } from '../components/Pods/Pod';

export const CustomerContext = React.createContext<CustomerInterface>(DefaultCustomer);

export const CustomerConsumer = CustomerContext.Consumer;

const UPDATED_AT = 'updatedAt';

export class CustomerProvider extends React.Component<any, CustomerInterface> {

    protected translations: { [x: string]: string; } = {};

    public constructor(props: any) {
        super(props);
        DefaultCustomer.refresh = () => this.fetchData();
        this.state = DefaultCustomer;
        DefaultCustomer.refresh();
    }

    protected getTranslation = (value: string): string => {
        const translation = this.translations[value];
        if (!translation || typeof translation === 'undefined') {
            console.error(`Translation for "${value}" is missing`);
            return value;
        }
        return translation;
    };

    /**
     * @return {void}
     */
    private shallSendToSurvey = (): void => {
        const { settings, status, surveyUrl } = this.state;

        if (!settings.hasSurvey) {
            return;
        }

        if (!status.isDelivered()) {
            return;
        }

        if (!surveyUrl) {
            return;
        }

        window.location.href = surveyUrl;
    };

    public dispatchError = (message: string | null, hasError = true): void => {
        this.setState({ error: { any: hasError, message: message } });
        console.error(this.state.error, this.state);
    };

    public fetchData = async () => {
        if (this.state.hash === null) {
            return;
        }

        try {
            const data = await axios.get(apiDeliveryEndpoint(this.state.hash));
            /*eslint camelcase: [
                "error", {
                    properties: "never",
                    ignoreDestructuring: true,
                    allow: ["is_delivered", "is_unhandled", "is_canceled", "shipment_number", "time_stamp", "external_id"]
                }
            ]*/
            const responseData = data.data.response_data;
            const { anonymized, customer, translations } = responseData;
            this.translations = translations;

            if (anonymized) {
                this.setState({ hasLoadedFromServer: true, anonymized: true });
                return <Anonymized />;
            }

            const { settings, notes, sender, packages, pickups, recycling, status } = customer;
            const pods = customer.pods.map((pod: { type: string; url: string; time_stamp: string }) => {
                const { time_stamp, url } = pod;
                return {
                    type: getPodTypeFromString(pod.type),
                    url,
                    timeStamp: time_stamp,
                };
            });

            const deliveryTimeInfo = {
                date: customer.delivery_date.date,
                dayForHumans: customer.delivery_date.day_for_humans,
                dateForHumans: customer.delivery_date.date_for_humans,
                estimatedDeliveryTime: customer.delivery_date.estimated_delivery_time,
                estimatedDeliveryTimeSpan: customer.delivery_date.estimated_delivery_time_span,
                timeWindow: customer.delivery_date.time_window,
                actualDeliveryTime: customer.delivery_date.actual_delivery_time,
            };

            interface PackagesApiInterface {
                name: string;
                external_id?: string;
                quantity?: string;
                status: string;
                packages: PackagesApiInterface[]
            }

            const buildPackageFromApi = (packagesArr: PackagesApiInterface, containedPackage: PackageInterface[]) => {
                return {
                    name: packagesArr.name,
                    externalId: packagesArr.external_id,
                    quantity: `${packagesArr.quantity}`,
                    packages: containedPackage,
                    status: packagesArr.status,
                };
            };

            const p = packages.map((apiPackage: PackagesApiInterface) => {
                const contained = Array.isArray(apiPackage.packages) ? apiPackage.packages.map(c => buildPackageFromApi(c, [])) : [];
                return buildPackageFromApi(apiPackage, contained);
            });
            const pUp = pickups.map((apiPackage: PackagesApiInterface) => {
                const contained = Array.isArray(apiPackage.packages) ? apiPackage.packages.map(c => buildPackageFromApi(c, [])) : [];
                return buildPackageFromApi(apiPackage, contained);
            });

            const r = recycling.map((apiPackage: PackagesApiInterface) => buildPackageFromApi(apiPackage, []));

            this.setState({
                _t: this.getTranslation,
                isCollect: customer.is_collect,
                isDelivery: customer.is_delivery,
                isPickup: customer.is_pickup,
                hasLoadedFromServer: true,
                customerId: customer.customer_id,
                customerName: customer.customer_name,
                receivedBy: customer.received_by,
                packages: p,
                pickUps: pUp,
                recycling: r,
                sender: {
                    name: sender.name,
                    customerServiceEmail: sender.customer_service_email,
                    customerServiceNumber: sender.customer_service_number,
                    customerService: sender.customer_service_text,
                },
                status: new Status(status.id, status.title, status.changes),
                driverLocation: customer.driver_location,
                externalId: customer.external_id,
                address: customer.address,
                surveyUrl: customer.survey_url,
                settings: {
                    showTrackingNumber: settings.show_tracking_number,
                    showEstimatedDeliveryTime: settings.show_estimated_delivery_time,
                    mayCancelDelivery: settings.cancel_delivery,
                    hasSurvey: settings.has_survey,
                    digitalSignatures: settings.digital_signatures,
                    allowChangeUnattendedOk: settings.allow_change_unattended_ok,
                    doAgeVerification: settings.do_age_verification,
                    doIdentityVerification: settings.do_identity_verification,
                    useBankIdQrCode: settings.use_bank_id_qr_code,
                },
                hasBoxKey: customer.has_box_key,
                routeIsLocked: customer.route_is_locked,
                locale: translations.locale,
                hasQrCode: customer.has_qr_code,
                proposeDays: customer.possible_customer_propose_days,
                customerSupportMarkup: customer.customer_support_markup,
                ageVerification: customer.age_verification,
                verificationCode: customer.verification_code,
                isIdentityVerified: customer.is_identity_verified,
                unattendedOk: customer.unattended_ok,
                mapProviderUrl: customer.map_provider_url,
                mapProviderName: customer.map_provider_name,
                notes: {
                    canEditNotes: notes.can_edit_notes,
                    title: notes.title,
                    subTitle: notes.sub_title,
                    saveButtonText: notes.save_button,
                    driverNotes: notes.driver_notes,
                },
                pods: pods,
                deliveryTimeInfo: deliveryTimeInfo,
            });

            if (this.state.hash && this.state.hasLoadedFromServer) {
                this.shallSendToSurvey();
                localStorage.setItem(this.state.hash, JSON.stringify(this.state));
                localStorage.setItem(UPDATED_AT, moment().unix().toString());
            }
        } catch (e) {
            console.error(e);
            const isRecovered = this.recoverDataFromLocalStorage();

            if (!isRecovered) {
                localStorage.clear();
                this.dispatchError('An error has occurred!');
            }
        }
    };

    public recoverDataFromLocalStorage(): boolean {
        if (!this.state.hash) {
            return false;
        }

        const localStorageData = localStorage.getItem(this.state.hash);
        if (!localStorageData) {
            return false;
        }

        const timeUpdated = localStorage.getItem(UPDATED_AT);
        if (!timeUpdated) {
            return false;
        }

        const twentyMinutesAgo = moment().subtract(20, 'minutes');
        if (!moment.unix(parseInt(timeUpdated)).isAfter(twentyMinutesAgo)) {
            localStorage.clear();
            return false; // data is too old to be recovered
        }

        const recoverdData: CustomerInterface = JSON.parse(localStorageData);

        const {
            isCollect,
            isDelivery,
            isPickup,
            customerId,
            customerName,
            receivedBy,
            packages,
            sender,
            status,
            driverLocation,
            externalId,
            address,
            surveyUrl,
            settings,
            routeIsLocked,
            hasQrCode,
            proposeDays,
            customerSupportMarkup,
            ageVerification,
            mapProviderUrl,
            mapProviderName,
            notes,
            pods,
            deliveryTimeInfo,
        } = recoverdData;

        this.setState({
            _t: this.getTranslation,
            isCollect,
            isDelivery,
            isPickup,
            hasLoadedFromServer: true,
            customerId,
            customerName,
            receivedBy,
            packages,
            sender,
            status,
            driverLocation,
            externalId,
            address,
            surveyUrl,
            settings,
            routeIsLocked,
            hasQrCode,
            proposeDays,
            customerSupportMarkup,
            ageVerification,
            mapProviderUrl,
            mapProviderName,
            notes,
            pods,
            deliveryTimeInfo,
        });

        return true;
    }

    public render(): JSX.Element {
        return <CustomerContext.Provider value={this.state}>{this.props.children}</CustomerContext.Provider>;
    }
}
