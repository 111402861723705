import React, { useContext, useState } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { Title, Row, Note } from '../../Customer/Layout';
import UnattendedOkButton from './UnattendedOkButton';
import axios from 'axios';
import { apiUpdateUnattended } from '../../../utils/api';
import './UnattendedOk.scss';

/*eslint camelcase: ["error", {allow: ["response_data", "not_ok", "IS_OK", "IS_NOT_OK"]}]*/

const IS_OK = true;
const IS_NOT_OK = false;

interface IUpdateUnattendedOkProps {
    allowUnattended: boolean;
    hash?: string;
}

const updateUnattendedOk = async (props: IUpdateUnattendedOkProps): Promise<string | null> => {
    const { hash, allowUnattended } = props;
    const data = {
        "unattended_ok": allowUnattended,
    };
    const response = await axios.post(apiUpdateUnattended(hash), data);
    return response.data.message || null;
};

export const UnattendedOk = () => {
    const { settings: { allowChangeUnattendedOk }, unattendedOk, hash, _t, isDelivery } = useContext(CustomerContext);
    const [isUnattendedOk, setIsUnattendedOk] = useState(unattendedOk);
    const [ongoingRequest, setOngoingRequest] = useState(false);

    const onChangeIsUnattendedOk = async (allowUnattended: boolean) => {
        setOngoingRequest(true);
        try {
            const responseMessage = await updateUnattendedOk({ allowUnattended, hash });
            if (responseMessage) {
                alert(responseMessage);
            }
            setIsUnattendedOk(allowUnattended);
        } catch (e) {
            console.error(e);
            alert(e);
        }
        setOngoingRequest(false);
    }

    if (!allowChangeUnattendedOk) {
        return <></>;
    }

    return (
        <Row>
            <Title title={_t('Allow unattended')} />
            <div id="unattended-ok">
                <UnattendedOkButton
                    disabled={!isUnattendedOk || ongoingRequest}
                    toggleUpdate={() => onChangeIsUnattendedOk(IS_NOT_OK)}
                    text={_t('Not ok')}
                    selected={!isUnattendedOk ? _t('selected') : null}
                />
                <UnattendedOkButton
                    disabled={isUnattendedOk || ongoingRequest}
                    toggleUpdate={() => onChangeIsUnattendedOk(IS_OK)}
                    text={_t('Ok')}
                    selected={isUnattendedOk ? _t('selected') : null}
                />
            </div>
            <Note
                text={
                    isDelivery ?
                    _t('I give my permission for the delivery to occur even if I am not present at the time of delivery, understanding that this means the package may be left at a designated location without my direct supervision.') :
                    _t('I give my permission for the pickup to occur even if I am not present at the time of pickup, understanding that this means the package may be retrieved by a designated person without my direct supervision.')
                }
            />
        </Row>
    );
};