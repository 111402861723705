import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import { SubTitle, Title, Row } from '../Customer/Layout';

export const Name = (): JSX.Element => {
    const { customerName, _t } = useContext(CustomerContext);

    return (
        <Row>
            <Title title={_t('Customer')} />
            <SubTitle title={customerName} />
        </Row>
    );
};
