import React, { useContext } from 'react';
import { ChangeInterface } from '../../../context/IStatus';
import { ThemeContext } from '../../../context/Theme';
import Icon from './Icon';

interface StatusChangeProps {
    change: ChangeInterface;
}
const StatusChange = (props: StatusChangeProps): JSX.Element | null => {
    const { textColor } = useContext(ThemeContext).colors;
    const { id, description, time, date } = props.change;

    if (!description) {
        return null;
    }

    return (
        <>
            <div className="status-wrapper">
                <Icon statusId={id} />
                <div style={{ borderColor: textColor }} className="status">
                    <div className="status-title">{`${date} ${time}`}</div>
                    <div>{description}</div>
                </div>
            </div>
            <hr style={{ borderColor: textColor }} className="status-divider" />
        </>
    );
};

export default StatusChange;
