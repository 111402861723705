import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';
import ExpectedHandleTime from '../../Customer/HandleTime/ExpectedHandleTime';
import TruckIcon from '../../SvgIcons/TruckIcon';
import CollectIcon from '../../SvgIcons/CollectIcon';
import { PackageList } from '../../Customer/Packages/PackageList';
import CancelOrder from '../../Cancel/CancelOrder';
import { QrCode } from '../../Customer/QrCode/QrCode';
import { Address } from '../../Customer/Address';
import { TrackingNumber } from '../../Customer/TrackingNumber';
import { CustomerService } from '../../Customer/CustomerService';
import { Name } from '../../Customer/Name';
import DriverNotes from '../../Customer/DriverNote/DriverNotes';
import { H2Title } from '../../Customer/Layout';
import { Signature } from '../../Customer/SignatureCanvas/Signature';
import { SenderLogoCard } from '../../Sender/SenderLogoCard';
import { EDrop } from '../../EDrop/EDrop';
import { Pods } from '../../Pods/Pods';
import CloseIcon from '../../SvgIcons/CloseIcon';
import './CustomerCard.scss';
import Status from '../../../context/Status';
import { UnattendedOk } from '../../Customer/UnattendedOk/UnattendedOk';
import Util from '../../../utils/Util';
import { Identification } from '../../Customer/Identification/Identification';
import { Toast, ToastType } from '../../Toast/Toast';

interface PropsInterface {
    handleClick(): void;
}

const CancelComponent = (): JSX.Element | null => {
    const { status, hash, settings } = useContext(CustomerContext);

    if (status.isCancelled() || hash === null) {
        return null;
    }

    if (settings.mayCancelDelivery) {
        return <CancelOrder />;
    }

    return null;
};

const CardWrapper = (props: { children: React.ReactNode }): JSX.Element => {
    const backgroundColor = useContext(ThemeContext).colors.lightColor;
    const { status } = useContext(CustomerContext);
    const className = `wrapper ${status.notStarted() || status.isHandled() ? 'full-size ' : ''}`;

    return (
        <div style={{ backgroundColor }} className={className}>
            {props.children}
        </div>
    );
};

const statusTitleClassName = (status: Status): ToastType => {
    if (status.isFailed()) {
        return ToastType.danger;
    }

    if (status.isDelivered()) {
        return ToastType.success;
    }

    if (status.isDeliveredNotHome()) {
        return ToastType.warning;
    }
    return ToastType.default;
};

const CardHeader = (props: PropsInterface): JSX.Element | null => {
    const { status, isDelivery, _t } = useContext(CustomerContext);
    const { lightColor } = useContext(ThemeContext).colors;
    const { handleClick } = props;

    return (
        <>
            {!status.isUnhandled() ? (
                <div style={{ margin: '0 auto' }}>
                    <SenderLogoCard backgroundColor={lightColor || null} />
                </div>
            ) : null}

            <div className="info-card-header">
                <div>
                    {isDelivery ? <TruckIcon /> : <CollectIcon />}
                    <H2Title title={_t('Order information')} />
                    <Toast type={statusTitleClassName(status)}>
                        <strong>
                            {_t(Util.getTitleText(status))}
                            {status.isCancelled() ? (
                                <>
                                    <br />
                                    <br />
                                    {_t('Customer page cancel post action info')}
                                </>
                            ) : null}
                        </strong>
                    </Toast>
                    <CustomerService />
                </div>

                {status.isUnhandled() ? (
                    <div onClick={handleClick}>
                        <CloseIcon />
                    </div>
                ) : null}
            </div>
        </>
    );
};

const CustomerCard = (props: PropsInterface): JSX.Element => {
    const { notes, status, hasQrCode, externalId } = useContext(CustomerContext);
    const { handleClick } = props;
    return (
        <CardWrapper>
            <CardHeader handleClick={handleClick} />
            <div className="info-card">
                <ExpectedHandleTime />
                <DriverNotes canEditNotes={notes.canEditNotes} driverNotes={notes.driverNotes} />
                <UnattendedOk />
                <EDrop />
                {hasQrCode && externalId ? <QrCode value={externalId} /> : null }
                <TrackingNumber />
                <Name />
                <Identification />
                <Address />
                <PackageList />
                <CancelComponent />
                {!status.isFailed() ? <Signature /> : null}
                <Pods />
            </div>
        </CardWrapper>
    );
};

export default CustomerCard;
