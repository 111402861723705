import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import { ThemeContext } from '../../context/Theme';
import './CustomerService.scss';
import Util from '../../utils/Util';

export interface CustomerServiceInterface extends CustomerServiceNumber, CustomerServiceEmail {
    customerService: string;
}

interface CustomerServiceNumber {
    customerServiceNumber: string | null;
}

interface CustomerServiceEmail {
    customerServiceEmail: string | null;
}

export const CustomerService = (): JSX.Element => {
    const { customerService } = useContext(CustomerContext).sender;
    const color = useContext(ThemeContext).colors.textColor;

    return (
        <div className="top-buffer">
            <em style={{ color }} className="my-2 note">
                {customerService} <PhoneNumber />
                <br />
                <Email />
            </em>
        </div>
    );
};

const PhoneNumber = (): JSX.Element | null => {
    const { customerServiceNumber } = useContext(CustomerContext).sender;
    const color = useContext(ThemeContext).colors.darkColor;

    if (Util.checkIsNull(customerServiceNumber)) {
        return null;
    }

    return (
        <a className="customer-service-number" style={{ color }} href={`tel:${customerServiceNumber}`}>
            {customerServiceNumber}
        </a>
    );
};

const Email = (): JSX.Element | null => {
    const { customerServiceEmail } = useContext(CustomerContext).sender;
    const color = useContext(ThemeContext).colors.darkColor;

    if (Util.checkIsNull(customerServiceEmail)) {
        return null;
    }

    return (
        <a className="customer-service-number" style={{ color }} href={`mailto:${customerServiceEmail}`}>
            {customerServiceEmail}
        </a>
    );
};
