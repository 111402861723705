import React from 'react';
import axios from 'axios';
import CancelButton from './CancelButton';
import CloseProposeDaysBtn from './CloseProposeDaysBtn';
import OngoingRequest from '../OngoingRequest';
import OpenProposeDaysBtn from './OpenProposeDaysBtn';
import ProposeDays from './ProposeDays';
import { CustomerContext } from '../../context/Customer';
import { Row } from '../Customer/Layout';
import { apiCancelOrderEndpoint } from '../../utils/api';
import './CancelOrder.scss';

interface StateInterface {
    message: string;
    showProposeDays: boolean;
    errorMessage: string | null;
    ongoingRequest: boolean;
    hasProposeDays: boolean;
    informationPreCancel: string;
    informationConfirmText: string;
    cancelBtnText: string;
}

export default class CancelOrder extends React.Component<any, StateInterface> {
    public static contextType = CustomerContext;

    private toFocus = React.createRef<HTMLDivElement>();

    /**
     * @param props {any}
     */
    public constructor(props: any) {
        super(props);
        this.state = {
            message: '',
            showProposeDays: false,
            errorMessage: null,
            ongoingRequest: false,
            hasProposeDays: false,
            informationPreCancel: '',
            informationConfirmText: '',
            cancelBtnText: '',
        };
    }

    /**
     * After component mmount do get customer from context
     */
    public componentDidMount(): void {
        const { proposeDays, _t } = this.context;

        this.setState({
            hasProposeDays: proposeDays.length > 0,
            informationPreCancel: _t('Customer page cancel pre action info'),
            informationConfirmText: _t('Are you sure you want to cancel yor order?'),
            cancelBtnText: _t('Cancel order'),
        });
    }

    /**
     * @param proposedDate {string|null}
     * @return {void}
     */
    public handleCancel = (proposedDate: string | null = null): void => {
        const { informationPreCancel, informationConfirmText } = this.state;
        const confirmText = `${informationPreCancel} ${informationConfirmText}`;

        const doCancel = window.confirm(confirmText);
        if (doCancel) {
            const { hash } = this.context;
            this.setState({ showProposeDays: false, ongoingRequest: true });

            /*eslint camelcase: ["error", {allow: ["delivery_cancelled", "response_data", "proposed_date"]}]*/
            axios
                .post(apiCancelOrderEndpoint(hash), {
                    proposed_date: proposedDate,
                })
                .then((response) => {
                    const {
                        data: {
                            response_data: { delivery_cancelled, message },
                        },
                    } = response;
                    if (delivery_cancelled) {
                        window.location.reload();
                    }
                    this.setState({ errorMessage: message, ongoingRequest: false });
                });
        }
    };

    public componentDidUpdate(): void {
        if (this.toFocus.current) {
            this.toFocus.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }

    /**
     * showProposeDays {boolean}
     * @return void
     */
    public setShowProposeDays = (showProposeDays: boolean): void => this.setState({ showProposeDays });

    public renderCancelOrder(): JSX.Element {
        const { hasProposeDays, showProposeDays, cancelBtnText } = this.state;

        if (!hasProposeDays) {
            return <CancelButton title={cancelBtnText} handleCancel={() => this.handleCancel(null)} />;
        }

        if (!showProposeDays) {
            return <OpenProposeDaysBtn onOpenClickEvent={() => this.setShowProposeDays(true)} />;
            // no ProposeDays show btn to cancel delivery
        }

        return (
            <>
                <CloseProposeDaysBtn onCloseClickEvent={() => this.setShowProposeDays(false)} />
                <ProposeDays handleCancel={this.handleCancel} />
                <div ref={this.toFocus} />
            </>
        );
    }

    public render(): JSX.Element {
        const { errorMessage, ongoingRequest, informationPreCancel } = this.state;

        if (errorMessage) {
            return <div className="cancel-delivery-warning">{errorMessage}</div>;
        }

        if (ongoingRequest) {
            return <OngoingRequest />;
        }

        return (
            <Row>
                <div className="cancel-delivery-warning">{informationPreCancel}</div>
                <div className="text--center">{this.renderCancelOrder()}</div>
            </Row>
        );
    }
}
