import React, { Component } from 'react';

export default class NotFound extends Component {
    private getText = (property: number) => {
        const texts = {
            en: [
                'Unfortunately, we were unable to find the information you are looking for. Please open the link you received via email or text message again!',
                'For more questions please contact the company where you have ordered your goods from.',
            ],
            sv: [
                'Tyvärr kunde vi inte hitta den information du letar efter. Vänligen öppna länken du mottog via e-post eller SMS!',
                'För mer frågor vänligen kontakta företaget där du har beställt dina varor från.',
            ],
        };

        switch (navigator.language) {
            case 'sv':
                return texts.sv[property];
            case 'en':
            case 'en-GB':
            case 'en-US':
            default:
                return texts.en[property];
        }
    };

    public render(): JSX.Element {
        return (
            <>
                {this.props.children}
                <div
                    style={{
                        padding: '20px',
                        maxWidth: '500px',
                        margin: '80px auto',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <h2>{this.getText(0)}</h2>

                    <h4>{this.getText(1)}</h4>
                </div>
            </>
        );
    }
}
