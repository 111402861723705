import React, { Component } from 'react';
import CloseIcon from '../SvgIcons/CloseIcon';
import './DeliveryStatusCard.scss';

interface StateInterface {
    title: string;
    subTitle: string | null;
    titleIsExpanded: boolean;
}

interface PropsInterface {
    title: string;
}

const maxTitleLength = 48;

export default class DeliveryStatusCard extends Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
        super(props);

        const { title } = props;
        const shallLimitTitle = title.length > maxTitleLength;

        this.state = {
            title: title,
            subTitle: shallLimitTitle ? title.substring(0, maxTitleLength) : null,
            titleIsExpanded: false,
        };
    }

    private renderButton(): JSX.Element | null {
        const { titleIsExpanded, subTitle } = this.state;

        if (subTitle === null && titleIsExpanded === false) {
            return null;
        }

        return (
            <strong
                className={`${titleIsExpanded ? 'text-is-expanded' : ''}`}
                onClick={() =>
                    this.setState({
                        titleIsExpanded: !titleIsExpanded,
                    })
                }
            >
                {titleIsExpanded ? <CloseIcon /> : <span className="blink">....</span>}
            </strong>
        );
    }

    public render(): JSX.Element {
        const { titleIsExpanded, title, subTitle } = this.state;

        return (
            <div className="position-in-route-wrapper">
                <span>
                    {titleIsExpanded === false && subTitle !== null ? subTitle : title}
                    {this.renderButton()}
                </span>
            </div>
        );
    }
}
