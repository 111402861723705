import { ChangeInterface, StatusInterfaces } from './IStatus';

export default class Status {
    private statusId: number;

    public changes: ChangeInterface[];

    public title: string;

    constructor(statusId: number, title: string, changes: ChangeInterface[]) {
        this.statusId = statusId;
        this.title = title;
        this.changes = changes;
    }

    private deliveryStatuses = (): StatusInterfaces => {
        return {
            notStarted: -1,
            unhandeld: 0,
            deliveredNotHome: 1,
            delivered: 2,
            notDelivered: 3,
            cancelled: 4,
        };
    };

    public static defaultStatus = (): Status => {
        return new Status(-1, '', []);
    };

    public notStarted = (): boolean => {
        return this.statusId === this.deliveryStatuses().notStarted;
    };

    public isStarted = (): boolean => {
        return !this.notStarted();
    };

    public isUnhandled = (): boolean => {
        return this.statusId === this.deliveryStatuses().unhandeld;
    };

    public isDeliveredNotHome = (): boolean => {
        return this.statusId === this.deliveryStatuses().deliveredNotHome;
    };

    public isDelivered = (): boolean => {
        return this.statusId === this.deliveryStatuses().delivered;
    };

    public isNotDelivered = (): boolean => {
        return this.statusId === this.deliveryStatuses().notDelivered;
    };

    public isCancelled = (): boolean => {
        return this.statusId === this.deliveryStatuses().cancelled;
    };

    public isFailed = (): boolean => {
        return this.isCancelled() || this.isNotDelivered();
    };

    public isHandled = (): boolean => {
        return this.isFailed() || this.isDelivered() || this.isDeliveredNotHome();
    };
}
