import React from 'react';
import { getEnv } from '../../../utils/config';
import { VerifyButton, VerifyButtonPropsInterface } from './VerifyButton';

const isDebugMode = getEnv('REACT_APP_IS_DEVELOPMENT', false);

interface PropsInterface {
    display: boolean;
    otherDevice: VerifyButtonPropsInterface;
    sameDevice: VerifyButtonPropsInterface;
}

export const VerifyButtons = (props: PropsInterface): JSX.Element => {
    const { otherDevice, sameDevice, display } = props;

    if (!display) {
        return <></>;
    }

    if (isDebugMode) {
        return (
            <>
                <VerifyButton onClickFunction={otherDevice.onClickFunction} title={otherDevice.title} />
                <VerifyButton onClickFunction={sameDevice.onClickFunction} title={sameDevice.title} />
            </>
        );
    }

    return <VerifyButton onClickFunction={sameDevice.onClickFunction} title={sameDevice.title} />;
}