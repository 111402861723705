import React from 'react';
import { getEnv } from '../../utils/config';

declare const window: any;

const appPublicPath = (): string => getEnv('REACT_APP_PUBLIC_PATH');

export const Loader = (): JSX.Element => {
    return (
        <div className="pinDeliver-logo">
            <img width="200" alt="pinDeliver" src={appPublicPath() + '/pinDeliver_logo.png'} />
        </div>
    );
};
