declare const window: any;

export const getEnv = (envName: string, throwError: boolean = true): any => {
    const val =
        window._env_ ?
            window._env_[envName] :
            process.env[envName];

    if (typeof val === 'undefined' && throwError) {
        throw Error(`Value "${envName}" not set in environment`);
    }

    return val;
};