import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { Toast, ToastType } from '../../Toast/Toast';

interface PropsInterface {
    showText: boolean
}

const AgeVerification = (props: PropsInterface): JSX.Element => {
    const { _t } = useContext(CustomerContext);
    if (!props.showText) {
        return <></>;
    }

    return (
        <Toast type={ToastType.danger}>
            <span>
                {_t('Note! This shipment includes age restricted items')}<br />
                {_t('The recipient must be over %s years old and be able to prove his/hers identity')}
            </span>
        </Toast>
    );
}

export default AgeVerification;