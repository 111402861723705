import React from 'react';
import CloseIcon from '../SvgIcons/CloseIcon';

interface ProposeInterface {
    onCloseClickEvent(): void;
}

const CloseProposeDaysBtn = (props: ProposeInterface): JSX.Element => {
    const { onCloseClickEvent } = props;
    return (
        <div onClick={onCloseClickEvent} style={{ cursor: 'pointer' }}>
            <CloseIcon />
        </div>
    );
};

export default CloseProposeDaysBtn;
