import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';

const SubTitle = (): JSX.Element => {
    const { status, _t, isCollect, deliveryTimeInfo, settings } = useContext(CustomerContext);
    const { estimatedDeliveryTime, date } = deliveryTimeInfo;

    if (status.isDeliveredNotHome() || status.isDelivered() || isCollect) {
        return <div>{date}</div>;
    }

    if (settings.showEstimatedDeliveryTime) {
        return <div>{`${_t('Preliminary')} ${estimatedDeliveryTime}`}</div>;
    }

    return <div>{date}</div>;
};

export default SubTitle;
