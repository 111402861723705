import React from 'react';

export enum TYPES {
    SIGNATURE = 'SIGNATURE',
    PHOTO = 'PHOTO',
}

export interface PodInterface {
    timeStamp: string;
    type: TYPES;
    url: string;
}

export const getPodTypeFromString = (type: string) => {
    switch (type) {
        case TYPES.PHOTO:
            return TYPES.PHOTO;
        case TYPES.SIGNATURE:
        default:
            return TYPES.SIGNATURE;
    }
};

export const PodPhoto = (props: { url: string }): JSX.Element => {
    return <img alt={TYPES.PHOTO} width="100%" src={props.url} />;
};
