import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';

interface EDropBtnInterface {
    handleOnClick: any;
}

export const EDropBtn = (props: EDropBtnInterface): JSX.Element => {
    const { _t } = useContext(CustomerContext);
    return (
        <div className="eDrop-btn">
            <button onClick={props.handleOnClick}>
                {_t('Click here to opt out of delivery to your cabinet')}
            </button>
        </div>
    );
};
