import React, { useContext } from 'react';
import { ThemeContext } from '../context/Theme';

const OngoingRequest = (): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;

    return (
        <div className="text--center">
            <span style={{ color: darkColor }} className="loader">
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        </div>
    );
};

export default OngoingRequest;
