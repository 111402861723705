import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import { SubTitle, Row, Title } from '../Customer/Layout';
import Directions from '../Map/Directions';
import { LocationInterface } from '../Map/Location';

export interface AddressInterface extends LocationInterface {
    description: string;
}

export const Address = (): JSX.Element => {
    const {
        isCollect,
        _t,
        address: { lat, lng, description },
    } = useContext(CustomerContext);

    return (
        <Row>
            <Title title={_t('Address')} />
            <SubTitle title={description} />
            {isCollect ? <Directions collection={{ lat, lng }} /> : null}
        </Row>
    );
};
