import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/Theme';

export interface DriverNoteInterface {
    id: number;
    value: string;
}

interface Props {
    callback: any;
    placeHolder: string;
    note: DriverNoteInterface;
}

export const DriverNote = (props: Props): JSX.Element => {
    const color = useContext(ThemeContext).colors.textColor;
    const {
        note: { id, value },
        callback,
        placeHolder,
    } = props;

    return (
        <textarea
            placeholder={`${placeHolder}...`}
            onKeyUp={(e) => callback(id, e.currentTarget.value)}
            defaultValue={value}
            style={{ color }}
        ></textarea>
    );
};
