import React, { useContext } from 'react';
import GoogleMap from './Google/GoogleMap';
import LeafletMap from './Leaflet/LeafletMap';
import { CustomerContext } from '../../context/Customer';

const Map = (): JSX.Element => {
    const { mapProviderUrl, mapProviderName } = useContext(CustomerContext);

    if (mapProviderUrl == null || mapProviderName == null || mapProviderName.toLowerCase().includes('google')) {
        return <GoogleMap />;
    }

    return <LeafletMap mapProviderUrl={mapProviderUrl} mapProviderName={mapProviderName} />;
};

export default Map;
