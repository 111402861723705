import React, { useContext } from 'react';
import './PackageList.scss';
import { CustomerContext } from '../../../context/Customer';
import { Title, Row } from '../Layout';
import { Package, Recycling, PackageInterface } from './Package';
import { StatusChanges } from '../../Customer/Status/StatusChanges';

interface PackageTableInterface {
    packages: PackageInterface[];
    title: string | null;
    subTitle: string | null;
}

const PackageTable = (props: PackageTableInterface): JSX.Element => {
    const { packages, title, subTitle } = props;
    if (packages.length < 1) {
        return <></>;
    }

    return (
        <table className="package-table">
            <thead>
                <tr>
                    <td colSpan={3}>
                        {title ? <Title title={title} /> : <></>}
                    </td>
                    <td>
                        {subTitle ? <Title title={subTitle} /> : <></>}
                    </td>
                </tr>
            </thead>
            <tbody>
                {packages.map((item, key) => <Package key={key} package={item} />)}
            </tbody>
        </table>
    );
};

const RecyclingTable = (): JSX.Element => {
    const { recycling, _t } = useContext(CustomerContext);
    if (recycling.length < 1) {
        return <></>;
    }

    return (
        <table className="package-table">
            <thead>
                <tr>
                    <td colSpan={3}>
                        <Title title={_t('Recycling')} />
                    </td>
                    <td>
                        <Title title={_t('Picked up')} />
                    </td>
                </tr>
            </thead>
            <tbody>
                {recycling.map((item, key) => <Recycling key={key} recycling={item} />)}
            </tbody>
        </table>
    );
};

export const PackageList = (): JSX.Element => {
    const { packages, pickUps, status, _t, isPickup } = useContext(CustomerContext);
    return (
        <>
            <Row>
                <PackageTable
                    packages={packages}
                    title={status.isDelivered() ? _t('Delivered') : (isPickup ? _t('Assignment') : _t('Packages'))}
                    subTitle={_t('Quantity')}
                />
            </Row>
            <Row>
                <PackageTable
                    packages={pickUps}
                    title={_t('Picked up')}
                    subTitle={_t('Quantity')}
                />
            </Row>
            <Row>
                <RecyclingTable />
            </Row>
            <Row>
                <StatusChanges />
            </Row>
        </>
    );
};
