import React, { useContext } from 'react';
import deliveryTruckFront from '../../../images/deliveryTruck-front.svg';
import { ThemeContext } from '../../../context/Theme';
import { CustomerContext } from '../../../context/Customer';
import { LocationInterface } from '../Location';

const TruckTile = (props: LocationInterface): JSX.Element | null => {
    const { truckPin, hasTheme } = useContext(ThemeContext);
    const { isDelivery, status } = useContext(CustomerContext);

    if (status.isUnhandled() && isDelivery) {
        if (hasTheme && truckPin) {
            return <img src={truckPin} alt="location" width="48" />;
        }

        return (
            <>
                <img src={deliveryTruckFront} alt="" width="48" className="truck-icon" />
                <div data-lat={props.lat} data-lng={props.lng} className="pulse black" />
            </>
        );
    }

    return null;
};

export default TruckTile;
