import React, { useContext } from 'react';
import { QrCode } from '../QrCode/QrCode';
import { ThemeContext } from '../../../context/Theme';

interface VerificationCodePropsInterface {
    verificationCode?: string,
    useBankIdQrCode: boolean,
}

export const VerificationCode = (props: VerificationCodePropsInterface): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;
    const { verificationCode, useBankIdQrCode } = props;
    if (useBankIdQrCode) {
        return <QrCode value={verificationCode} displayValue={true} />;
    }

    if (verificationCode) {
        const bgColor = darkColor ? darkColor : '#06d4fe';
        return <h1 style={{ color: bgColor }} className="verification-code">{verificationCode}</h1>;
    }

    return <></>;
}