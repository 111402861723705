import Status from "../context/Status";

export default class Util {
    /**
     * Will check if a value is empty
     */
    public static checkIsNull(value?: null | string): boolean {
        if (value === null || typeof value === 'undefined' || value === '') {
            return true;
        }

        return false;
    }

    public static getStringOrDefault(value?: null | string, defaultValue: string | null = null): string|null {
        if (Util.checkIsNull(value)) {
            return defaultValue;
        }

        return value || defaultValue;
    }

    public static getTitleText(currentStatus: Status): string {
        if (currentStatus.isDeliveredNotHome()) {
            return 'Title for not home';
        }

        if (currentStatus.isDelivered()) {
            return 'Title for delivered';
        }

        if (currentStatus.isNotDelivered()) {
            return 'Title for not delivered';
        }

        if (currentStatus.isCancelled()) {
            return 'Title for cancelled';
        }

        return 'Title for unhandled';
    };
}