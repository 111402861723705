import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/Theme';

const EstimatedDeliveryTimeWrapper = (props: { children: React.ReactNode }): JSX.Element => {
    const { textColor } = useContext(ThemeContext).colors;
    return (
        <div style={{ color: textColor }} className="sub-title">
            {props.children}
        </div>
    );
};

export default EstimatedDeliveryTimeWrapper;
