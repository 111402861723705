import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';

interface ProposeInterface {
    onOpenClickEvent(): void;
}

const OpenProposeDaysBtn = (props: ProposeInterface): JSX.Element => {
    const { _t} = useContext(CustomerContext);
    const { onOpenClickEvent } = props;

    return (
        <div>
            <button className="cancel-delivery-btn" type="button" onClick={onOpenClickEvent}>
                {_t('Cancel order')}
            </button>
        </div>
    );
};

export default OpenProposeDaysBtn;
