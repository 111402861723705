import React, { useContext } from 'react';
import { ThemeContext } from '../../context/Theme';

interface TitleProps {
    title: string | null;
}

export const Title = (props: TitleProps): JSX.Element | null => {
    const { darkColor } = useContext(ThemeContext).colors;
    const { title } = props;
    if (title === null || title.length < 1) {
        return null;
    }

    return (
        <span style={{ color: darkColor }} className="title my-2">
            {title}
        </span>
    );
};

export const H2Title = (props: TitleProps): JSX.Element | null => {
    const { darkColor } = useContext(ThemeContext).colors;
    const { title } = props;
    if (title === null || title.length < 1) {
        return null;
    }

    return <h2 style={{ color: darkColor }}>{title}</h2>;
};

export const SubTitle = (props: TitleProps): JSX.Element | null => {
    const { textColor } = useContext(ThemeContext).colors;
    const { title } = props;
    if (title === null || title.length < 1) {
        return null;
    }

    return (
        <div style={{ color: textColor }} className="sub-title">
            {title}
        </div>
    );
};

export const Row = (props: { children: React.ReactNode }): JSX.Element => {
    return <div className="row">{props.children}</div>;
};

export const Note = (props: { text: string | null }): JSX.Element | null => {
    const { textColor } = useContext(ThemeContext).colors;
    const { text } = props;
    if (text === null || text.length < 1) {
        return null;
    }

    return (
        <em style={{ color: textColor }} className="note">
            {text}
        </em>
    );
};
