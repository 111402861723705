import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';

const DeliveryTime = (): JSX.Element => {
    const { status, isCollect, deliveryTimeInfo } = useContext(CustomerContext);
    const { timeWindow, estimatedDeliveryTimeSpan, actualDeliveryTime } = deliveryTimeInfo;

    if (status.isDeliveredNotHome() || status.isDelivered()) {
        return <div>{actualDeliveryTime}</div>;
    }

    if (isCollect) {
        return <div>{timeWindow}</div>;
    }

    return <div>{estimatedDeliveryTimeSpan}</div>;
};

export default DeliveryTime;
