import React from 'react';
import { QrCode } from '../QrCode/QrCode';
import * as crypto from 'crypto';
import { getEnv } from '../../../utils/config';
import { TokensInterface } from './Tokens';

const isDebugMode = getEnv('REACT_APP_IS_DEVELOPMENT', false);

const hmacsha256 = (secret: string, time: number): string => {
    const msg = time.toString();

    if (isDebugMode) {
        console.log('htime:' + msg);
    }

    return crypto.createHmac('sha256', secret).update(msg).digest('hex');
};

interface VerifyOnOtherDeviceQrCodePropsInterface {
    time: number,
    tokens: TokensInterface,
    setTime: (t: number) => void,
}

const VerifyOnOtherDeviceQrCode = (props: VerifyOnOtherDeviceQrCodePropsInterface): JSX.Element => {
    const { time, tokens, setTime } = props;

    const canDisplayQrCode = tokens.qrStartSecret.length > 0 && tokens.qrStartToken.length > 0 && time >= 0;

    const getQrCodeValue = () => {
        if (isDebugMode) {
            console.log(`qr-time:${time}`);
        }

        let { qrStartToken, qrStartSecret } = tokens;
        const authCode = hmacsha256(qrStartSecret, time);
        const result = `bankid.${qrStartToken}.${time}.${authCode}`;

        if (isDebugMode) {
            console.log(`qr-code=${result}`);
        }

        return result;
    };

    if (!canDisplayQrCode) {
        return <></>;
    }

    setTimeout(() => setTime(time + 1), 1000);
    return <QrCode value={getQrCodeValue()} />
}

export default VerifyOnOtherDeviceQrCode;