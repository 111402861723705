import React, { useContext, useState } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';
import { Title } from '../../Customer/Layout';
import ShowAllBtn from './ShowAllBtn';
import StatusChange from './StatusChange';
import './statusChanges.scss';

export const StatusChanges = (): JSX.Element | null => {
    const { changes, title } = useContext(CustomerContext).status;
    const { textColor } = useContext(ThemeContext).colors;

    const [showStatusChanges] = useState(changes.length > 0);
    const [showAll, setShowAll] = useState(false);

    if (!showStatusChanges) {
        return null;
    }

    const latestChanges = () => {
        if (changes[0] && changes[1]) {
            return [changes[0], changes[1]];
        }

        if (changes[0]) {
            return [changes[0]];
        }

        return [];
    };

    const renderShowAllBtn = changes.length > 2;

    return (
        <div style={{ color: textColor }} className="statuses">
            <Title title={title} />
            {showAll
                ? changes.map((c) => <StatusChange key={c.id} change={c} />)
                : latestChanges().map((c) => <StatusChange key={c.id} change={c} />)}
            <ShowAllBtn showBtn={renderShowAllBtn} setShowAll={setShowAll} showAll={showAll} />
        </div>
    );
};
