import React from 'react';

interface IUnattendedOkButton {
    disabled: boolean;
    toggleUpdate(): void;
    text: string;
    selected: string|null
}

const UnattendedOkButton = (props: IUnattendedOkButton) => {
    const { disabled, toggleUpdate, text, selected } = props;
    return (
        <button onClick={toggleUpdate} disabled={disabled}>
            {selected ? (`${text} (${selected})`) : text}
        </button>
    );
}

export default UnattendedOkButton;