import React, { useContext } from 'react';
import truckWhite from '../../../images/truck-white.svg';
import TruckIcon from '../../SvgIcons/TruckIcon';
import CollectIcon from '../../SvgIcons/CollectIcon';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';

interface PropsInterface {
    handleClick(): void;
    cardIsOpen: boolean;
}

export const OpenCardButton = (props: PropsInterface): JSX.Element | null => {
    const { cardIsOpen, handleClick } = props;
    const { isCollect } = useContext(CustomerContext);
    const { colors, hasTheme } = useContext(ThemeContext);

    if (cardIsOpen) {
        return null;
    }

    if (hasTheme) {
        return (
            <button
                style={{
                    background: colors.lightColor,
                }}
                onClick={handleClick}
                className="open-info-card-btn"
            >
                {isCollect ? <CollectIcon /> : <TruckIcon />}
            </button>
        );
    }

    return (
        <button onClick={handleClick} className="open-info-card-btn">
            <img alt="truck" src={truckWhite} />
        </button>
    );
};
