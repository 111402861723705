import React from 'react';
import CloseIcon from '../SvgIcons/CloseIcon';

interface ErrorProps {
    message: null | string;
}

interface ErrorState extends ErrorProps {
    showError: boolean;
}

export interface ErrorInterface extends ErrorProps {
    any: boolean;
}

export default class ErrorMessage extends React.Component<ErrorProps, ErrorState> {
    public constructor(props: ErrorProps) {
        super(props);
        const { message } = this.props;

        this.state = {
            showError: message !== null,
            message: message,
        };

        setTimeout(() => this.setState({ showError: false }), 10000);
    }

    public render(): JSX.Element | null {
        const { showError, message } = this.state;

        if (!showError) {
            return null;
        }
        return (
            <div
                style={{
                    margin: 0,
                    zIndex: 100,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    lineHeight: 2.5,
                }}
                className="alert alert-danger"
            >
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        padding: '0 1.25rem',
                        color: 'inherit',
                    }}
                >
                    <button
                        style={{ background: 'none', border: 'none' }}
                        type="button"
                        onClick={() => this.setState({ showError: false })}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <strong>{message}</strong>
                </div>
            </div>
        );
    }
}
