import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import DeliveryStatusCard from '../DeliveryStatus/DeliveryStatusCard';
import { SenderLogoCard } from '../Sender/SenderLogoCard';
import './HeaderCard.scss';
import Util from '../../utils/Util';

export const HeaderCard = (): JSX.Element => {
    const { _t, status } = useContext(CustomerContext);
    return (
        <div className="header fixed">
            <SenderLogoCard backgroundColor={'#ffffff'} />
            <DeliveryStatusCard title={_t(Util.getTitleText(status))} />
        </div>
    );
};
