import React from 'react';
import Map from '../components/Map/Map';
import { HeaderCard } from '../components/Header/HeaderCard';
import FooterCard from '../components/Footer/FooterCard';

const DistributionIsOngoing = (): JSX.Element => {
    return (
        <>
            <HeaderCard />
            <Map />
            <FooterCard />
        </>
    );
};

export default DistributionIsOngoing;
