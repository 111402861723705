import React from 'react';

import ARRIVED_AT_TERMINAL from './icons/ARRIVED_AT_TERMINAL.svg';
import CANCELLED from './icons/CANCELLED.svg';
import CREATED from './icons/CREATED.svg';
import DELIVERED_NOT_HOME from './icons/DELIVERED_NOT_HOME.svg';
import DELIVERED from './icons/DELIVERED.svg';
import NOT_DELIVERED from './icons/NOT_DELIVERED.svg';
import PARTIAL_DELIVERED from './icons/PARTIAL_DELIVERED.svg';
import UNHANDLED from './icons/UNHANDLED.svg';

const packageStatus = {
    UNHANDLED: { id: 1, color: '#a9e6c3' },
    NOT_DELIVERED: { id: 2, color: '#ffcccc' },
    DELIVERED: { id: 3, color: '#a9e6c3' },
    CANCELLED: { id: 4, color: '#ffcccc' },
    PARTIAL_DELIVERED: { id: 5, color: '#ffcc66' },
    DELIVERED_NOT_HOME: { id: 6, color: '#a9e6c3' },
    CREATED: { id: 7, color: '#d3eff7' },
    ARRIVED_AT_TERMINAL: { id: 8, color: '#a9e6c3' },
    LOADED: { id: 9, color: '#a9e6c3' },
};

interface IconProps {
    statusId: number;
}

const Icon = (props: IconProps): JSX.Element | null => {
    let color = null;
    let image = null;

    const { statusId } = props;
    switch (statusId) {
        case packageStatus.LOADED.id:
        case packageStatus.UNHANDLED.id:
            image = UNHANDLED;
            color = packageStatus.UNHANDLED.color;
            break;
        case packageStatus.NOT_DELIVERED.id:
            image = NOT_DELIVERED;
            color = packageStatus.NOT_DELIVERED.color;
            break;
        case packageStatus.DELIVERED.id:
            image = DELIVERED;
            color = packageStatus.DELIVERED.color;
            break;
        case packageStatus.CANCELLED.id:
            image = CANCELLED;
            color = packageStatus.CANCELLED.color;
            break;
        case packageStatus.PARTIAL_DELIVERED.id:
            image = PARTIAL_DELIVERED;
            color = packageStatus.PARTIAL_DELIVERED.color;
            break;
        case packageStatus.DELIVERED_NOT_HOME.id:
            image = DELIVERED_NOT_HOME;
            color = packageStatus.DELIVERED_NOT_HOME.color;
            break;
        case packageStatus.CREATED.id:
            image = CREATED;
            color = packageStatus.CREATED.color;
            break;
        case packageStatus.ARRIVED_AT_TERMINAL.id:
            image = ARRIVED_AT_TERMINAL;
            color = packageStatus.ARRIVED_AT_TERMINAL.color;
            break;
    }

    if (!color || !image) {
        return null;
    }

    return (
        <div style={{ background: color }} className="status-image">
            <img alt="delivery status" src={image} />
        </div>
    );
};

export default Icon;
