import React from 'react';

interface PropsInterface {
    title: string;
    handleCancel: any;
}

const CancelButton = (props: PropsInterface): JSX.Element => {
    const { handleCancel, title } = props;

    return (
        <div>
            <button type="button" className="cancel-delivery-btn" onClick={() => handleCancel(null)}>
                {title}
            </button>
        </div>
    );
};

export default CancelButton;
