import React, { useContext, useState } from 'react';
import { CustomerContext } from '../../context/Customer';
import CustomerCard from '../Info/Card/CustomerCard';
import { OpenCardButton } from '../Info/Button/OpenCardButton';

import TimeToArrivalCard from '../TimeToArrival/TimeToArrivalCard';
import './FooterCard.scss';

const Footer = (): JSX.Element => {
    const { status } = useContext(CustomerContext);
    const [cardIsOpen, setCardIsOpen] = useState(status.notStarted() || status.isHandled());

    return (
        <>
            <div className="footer">
                <TimeToArrivalCard />
                <OpenCardButton cardIsOpen={cardIsOpen} handleClick={() => setCardIsOpen(!cardIsOpen)} />
            </div>
            {cardIsOpen ? <CustomerCard handleClick={() => setCardIsOpen(!cardIsOpen)} /> : null}
        </>
    );
};

export default Footer;
