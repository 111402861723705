import React from 'react';
import './Toast.scss';

export enum ToastType {
    warning = 'warning',
    success = 'success',
    danger = 'danger',
    default = 'default',
}

interface ToastPropsInterface {
    children?: React.ReactElement;
    message?: string;
    type?: ToastType
}

export const Toast = (props: ToastPropsInterface): JSX.Element => {
    const { children, type, message } = props;

    if (message) {
        return (
            <div className="toast">
                <div className={type || ToastType.default}>{message}</div>
            </div>
        );
    } else if (children) {
        return (
            <div className="toast">
                <div className={type || ToastType.default}>{children}</div>
            </div>
        );
    } else {
        return <></>;
    }
}