export interface TokensInterface {
    orderRef: string;
    autoStartToken: string;
    qrStartToken: string;
    qrStartSecret: string;
}

export const defaultTokens: TokensInterface =
{
    orderRef: '',
    autoStartToken: '',
    qrStartToken: '',
    qrStartSecret: '',
};