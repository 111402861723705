import React, { Component, CSSProperties } from 'react';
import * as L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CustomerConsumer } from '../../../context/Customer';
import '../Map.scss';
import CustomerTile from './CustomerTile';
import TruckTile from './TruckTile';

const mapStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 0,
};

interface LeafletProps {
    mapProviderUrl: string;
    mapProviderName: string;
}

export default class LeafletMap extends Component<LeafletProps, any> {
    public constructor(props: LeafletProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <CustomerConsumer>
                {(customer) => {
                    const zoom = 13;
                    const center: L.LatLngExpression = [customer.address.lat, customer.address.lng];
                    const attribution =
                        '&copy <a href="http://osm.org/copyright">' + this.props.mapProviderName + '</a> contributors';
                    let bounds = undefined;
                    if (customer.status.isUnhandled() && customer.isDelivery) {
                        bounds = L.latLngBounds(
                            [customer.address.lat, customer.address.lng],
                            [customer.address.lat, customer.address.lng],
                        );
                        bounds.extend([customer.driverLocation.lat, customer.driverLocation.lng]);
                    }
                    return (
                        <div style={mapStyle}>
                            <Map
                                style={{ height: '100%', zIndex: 0 }}
                                zoom={zoom}
                                bounds={bounds}
                                boundsOptions={{ paddingTopLeft: [100, 100], paddingBottomRight: [100, 100] }}
                                center={center}
                            >
                                <TileLayer attribution={attribution} url={this.props.mapProviderUrl} />
                                <CustomerTile />
                                <TruckTile />
                            </Map>
                        </div>
                    );
                }}
            </CustomerConsumer>
        );
    }
}
