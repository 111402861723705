import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import refreshIconDark from '../../images/update_dark.svg';

const RefreshBtn = (): JSX.Element | null => {
    const { status, refresh } = useContext(CustomerContext);

    if (status.isUnhandled()) {
        return (
            <div className="light-theme-background refresh" onClick={refresh}>
                <img src={refreshIconDark} alt="refresh" />
            </div>
        );
    }

    return null;
};

export default RefreshBtn;
