import React, { Component } from 'react';
import { CustomerConsumer } from '../../context/Customer';
import { Bicycle, Car, RailRoad, Walking } from './MapIcons';
import './Directions.scss';
import { LocationInterface } from './Location';

interface LinkPropsInterface {
    location: LocationInterface;
    travelmode: string;
    children: React.ReactNode;
}

const Link = (props: LinkPropsInterface): JSX.Element => {
    const {
        location: { lat, lng },
        travelmode,
    } = props;
    const baseUrl = 'https://www.google.com/maps/dir';
    const url = `${baseUrl}/?api=1&destination=${lat},${lng}&travelmode=${travelmode}`;

    return (
        <a rel="noopener noreferrer" target="_blank" href={url} className="direction">
            {props.children}
        </a>
    );
};

interface PropsInterface {
    collection: LocationInterface;
}

export default class Directions extends Component<PropsInterface, any> {
    private travelmode(): { driving: string; walking: string; bicycling: string; transit: string } {
        return {
            driving: 'driving',
            walking: 'walking',
            bicycling: 'bicycling',
            transit: 'transit',
        };
    }

    public render(): JSX.Element {
        const { driving, walking, bicycling, transit } = this.travelmode();
        const { collection } = this.props;

        return (
            <CustomerConsumer>
                {(customer) => (
                    <>
                        {!customer.status.isUnhandled() && customer.isCollect ? null : (
                            <div className="directions-wrapper">
                                <Link location={collection} travelmode={driving}>
                                    <Car />
                                </Link>

                                <Link location={collection} travelmode={bicycling}>
                                    <Bicycle />
                                </Link>

                                <Link location={collection} travelmode={transit}>
                                    <RailRoad />
                                </Link>

                                <Link location={collection} travelmode={walking}>
                                    <Walking />
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </CustomerConsumer>
        );
    }
}
