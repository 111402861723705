import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';
import './ExpectedHandleTime.scss';
import { Title, Row, Note } from '../Layout';
import { CollectOrder } from './CollectOrder';
import { EstimatedDeliveryTime } from './EstimatedDeliveryTime';
import EstimatedDeliveryTimeWrapper from './EstimatedDeliveryTimeWrapper';

const DeliveryNote = (): JSX.Element => {
    const { status, isCollect, _t, isPickup } = useContext(CustomerContext);
    if (status.isUnhandled() && !isCollect) {
        if (isPickup) {
            return <Note text={_t('Note that the pickup times can be changed.')} />;
        }
        return <Note text={_t('Note that the delivery times can be changed during a ongoing delivery.')} />;
    }

    return <></>;
}

const DeliveryTimes = (): JSX.Element => {
    const { status, isCollect, deliveryTimeInfo } = useContext(CustomerContext);

    const isUnhandledOrNotStated = status.isUnhandled() || status.notStarted();
    const isCompleteOrCanceled = !isUnhandledOrNotStated;

    if (isCompleteOrCanceled) {
        const { date, actualDeliveryTime } = deliveryTimeInfo;
        return (
            <EstimatedDeliveryTimeWrapper>
                {/* ActualDeliveryTime */}
                <span>{`${date} ${actualDeliveryTime}`}</span>
            </EstimatedDeliveryTimeWrapper>
        );
    }

    if (isCollect && isUnhandledOrNotStated) {
        return (
            <>
                <EstimatedDeliveryTimeWrapper>
                    <CollectOrder />
                </EstimatedDeliveryTimeWrapper>
            </>
        );
    }

    return (
        <>
            <EstimatedDeliveryTimeWrapper>
                <EstimatedDeliveryTime />
            </EstimatedDeliveryTimeWrapper>
            <DeliveryNote />
        </>
    );
};

const ExpectedHandleTime = (): JSX.Element | null => {
    const { status, isCollect, deliveryTimeInfo, routeIsLocked, _t } = useContext(CustomerContext);

    // Tender component if the order is click and collect or if we have an delveiry date to show
    // At this state the customer is unplanned
    const showExpectedHandleTime = isCollect || deliveryTimeInfo.date !== null;

    if (status.isNotDelivered() && isCollect) {
        return null;
    }

    if (!showExpectedHandleTime || !routeIsLocked) {
        return null;
    }

    return (
        <Row>
            <Title title={_t('Time delivered')} />
            <DeliveryTimes />
        </Row>
    );
};

export default ExpectedHandleTime;
