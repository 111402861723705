import React, { useContext } from 'react';
import './TimeToArrivalCard.scss';
import { CustomerContext } from '../../context/Customer';
import { ThemeContext } from '../../context/Theme';
import RefreshBtn from './RefreshBtn';
import SubTitle from './SubTitle';
import DeliveryTime from './DeliveryTime';

const TimeToArrivalCard = (): JSX.Element | null => {
    const { status, _t } = useContext(CustomerContext);
    const { darkColor } = useContext(ThemeContext).colors;

    if (status.notStarted() || status.isHandled()) {
        return null;
    }

    const title = status.isDelivered() ? _t('Title for delivered') : _t('Sub title for unhandled');

    return (
        <div style={{ background: darkColor }} className="time-to-arrival-wrapper">
            <div>{title}</div>
            <DeliveryTime />
            <SubTitle />
            <RefreshBtn />
        </div>
    );
};

export default TimeToArrivalCard;
