export interface ThemeInterface {
    logo?: string;
    hasTheme: boolean;
    fontName?: string;
    fontUrl?: string;
    customerPin?: string;
    truckPin?: string;
    colors: {
        darkColor?: string;
        lightColor?: string;
        textColor?: string;
    };
}

export const DefaultTheme: ThemeInterface = {
    hasTheme: false,
    colors: {},
};
