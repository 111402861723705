import React, { useContext, useState } from 'react';
import { PackageNotDeliveredIcon, PackageDeliveredIcon } from './PackageIcons';
import { ThemeContext } from '../../../context/Theme';

enum STATUS {
    CANCELLED = 'CANCELLED',
    DELIVERED = 'DELIVERED',
    NOT_DELIVERED = 'NOT_DELIVERED',
    UNHANDLED = 'UNHANDLED',
}

interface PInterface {
    name: string;
    externalId?: string;
    quantity?: string;
    status: string;
}

export interface PackageInterface extends PInterface {
    packages: PInterface[];
}

interface ExpandedRowInterface {
    showRow: boolean;
    color?: string;
    packages: PInterface[];
}
const ExpandedRow = (props: ExpandedRowInterface): JSX.Element => {
    const { showRow, color, packages } = props;
    if (!showRow) return <></>;

    return (
        <>
            {
                packages.map((p, key) => (
                    <tr key={key} style={{ color }}>
                        <td></td>
                        <td>
                            <PackageIsDelivered status={p.status} />
                        </td>
                        <td>{renderPackageName(p.name, p.status)}</td>
                        <td>{p.externalId || ''}</td>
                    </tr>
                ))
            }
        </>
    );
}

const renderPackageName = (title: string, status: string) => status === STATUS.CANCELLED ? <s>{title}</s> : <span>{title}</span>;

export const Package = (props: { package: PackageInterface }): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState(false);
    const color = useContext(ThemeContext).colors.textColor;
    const { name, quantity, packages, status } = props.package;
    const isUnhandled = status === STATUS.UNHANDLED;
    const isCanceled = status === STATUS.CANCELLED;
    const canDisplayExpandedRow = packages.length > 0 && (!isUnhandled && !isCanceled);

    return (
        <>
            <tr style={{ color }}>
                {canDisplayExpandedRow ?
                    (<td className="expand" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? '-' : '+'}</td>) :
                    (<td><PackageIsDelivered status={props.package.status} /></td>)
                }
                <td colSpan={2}>{renderPackageName(name, status)}</td>
                <td>{quantity}</td>
            </tr>
            {canDisplayExpandedRow ?
                (<ExpandedRow color={color} showRow={isExpanded} packages={packages} />) :
                (<></>)
            }
        </>
    );
};

export const Recycling = (props: { recycling: PackageInterface }): JSX.Element => {
    const color = useContext(ThemeContext).colors.textColor;
    const { name, quantity } = props.recycling;
    return (
        <tr style={{ color }}>
            <td colSpan={3}>{name}</td>
            <td>{quantity}</td>
        </tr>
    );
};

const PackageIsDelivered = (props: { status: string }): JSX.Element | null => {
    const { status } = props;
    if (status === STATUS.DELIVERED) return <PackageDeliveredIcon />;
    if (status === STATUS.UNHANDLED || status === STATUS.CANCELLED) return null;

    return <PackageNotDeliveredIcon />;
};
