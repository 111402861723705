import React, { useContext } from 'react';
import { ThemeContext } from '../../context/Theme';

const TruckIcon = (): JSX.Element => {
    const { colors } = useContext(ThemeContext);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53.369" height="42.47" viewBox="0 0 53.369 42.47">
            <g id="Lasbil_blå" data-name="Lasbil blå" transform="translate(0 -52.277)">
                <g id="Group_281" data-name="Group 281" transform="translate(0 52.277)">
                    <g id="Group_280" data-name="Group 280">
                        <path
                            id="Path_737"
                            data-name="Path 737"
                            d="M41.827,52.277A11.56,11.56,0,0,0,30.444,61.9H15.912a3.642,3.642,0,0,0-3.638,3.638v2.684H5.523A5.529,5.529,0,0,0,0,73.749V86.994a3.251,3.251,0,0,0,3.247,3.247H5.472a6.069,6.069,0,0,0,11.729,0h8.9a6.069,6.069,0,0,0,11.729,0h1.847A3.642,3.642,0,0,0,43.319,86.6V75.265a11.542,11.542,0,0,0-1.492-22.988ZM5.472,87.115H3.247a.121.121,0,0,1-.121-.121V73.749a2.4,2.4,0,0,1,2.4-2.4h6.751V82.681a6.07,6.07,0,0,0-6.8,4.434Zm5.864,4.506a2.943,2.943,0,1,1,2.943-2.943A2.946,2.946,0,0,1,11.336,91.621Zm20.633,0a2.946,2.946,0,0,1-2.943-2.942h0a2.943,2.943,0,1,1,2.943,2.944ZM40.192,86.6a.512.512,0,0,1-.512.512H37.833a6.069,6.069,0,0,0-11.729,0H17.2a6.078,6.078,0,0,0-1.8-2.94V65.542a.512.512,0,0,1,.512-.511H30.348a11.565,11.565,0,0,0,9.844,10.214V86.6Zm1.634-14.369a8.416,8.416,0,1,1,8.416-8.416A8.425,8.425,0,0,1,41.827,72.235Z"
                            transform="translate(0 -52.277)"
                            fill={colors.darkColor || '#fff'}
                        />
                    </g>
                </g>
                <g id="Group_283" data-name="Group 283" transform="translate(9.772 87.115)">
                    <g id="Group_282" data-name="Group 282">
                        <path
                            id="Path_738"
                            data-name="Path 738"
                            d="M96.872,387.907a1.428,1.428,0,0,0-.023-.152,1.238,1.238,0,0,0-.038-.148c-.015-.049-.032-.1-.051-.144s-.042-.094-.066-.139a1.521,1.521,0,0,0-.078-.131,1.5,1.5,0,0,0-.092-.124,1.334,1.334,0,0,0-.1-.114,1.312,1.312,0,0,0-.114-.1,1.492,1.492,0,0,0-.124-.092,1.464,1.464,0,0,0-.131-.078c-.045-.024-.092-.046-.139-.066a1.391,1.391,0,0,0-.144-.051,1.259,1.259,0,0,0-.148-.038,1.545,1.545,0,0,0-.46-.023,1.511,1.511,0,0,0-.151.023,1.247,1.247,0,0,0-.149.038,1.508,1.508,0,0,0-.144.051c-.047.02-.094.042-.139.066s-.089.05-.131.078a1.322,1.322,0,0,0-.123.092,1.361,1.361,0,0,0-.115.1,1.337,1.337,0,0,0-.1.114c-.032.04-.063.081-.092.124s-.054.087-.078.131a1.418,1.418,0,0,0-.065.139,1.4,1.4,0,0,0-.052.144c-.015.049-.027.1-.038.148a1.484,1.484,0,0,0-.023.152,1.5,1.5,0,0,0-.007.153,1.521,1.521,0,0,0,.007.154,1.505,1.505,0,0,0,.023.151c.01.05.023.1.038.149a1.386,1.386,0,0,0,.052.144,1.418,1.418,0,0,0,.065.139c.024.045.051.089.078.131s.059.084.092.123a1.524,1.524,0,0,0,.1.115,1.577,1.577,0,0,0,.238.194c.043.028.087.055.131.079s.092.046.139.065a1.484,1.484,0,0,0,.144.052c.049.015.1.027.149.038s.1.018.151.023a1.535,1.535,0,0,0,.154.007c.051,0,.1,0,.153-.007s.1-.014.152-.023.1-.023.148-.038a1.39,1.39,0,0,0,.144-.052c.047-.019.094-.042.139-.065a1.466,1.466,0,0,0,.131-.079,1.515,1.515,0,0,0,.124-.091,1.323,1.323,0,0,0,.114-.1,1.517,1.517,0,0,0,.1-.115,1.342,1.342,0,0,0,.092-.123q.042-.064.078-.131c.024-.045.046-.092.066-.139s.036-.1.051-.144a1.239,1.239,0,0,0,.038-.149,1.5,1.5,0,0,0,.023-.151,1.524,1.524,0,0,0,.008-.154A1.508,1.508,0,0,0,96.872,387.907Z"
                            transform="translate(-93.753 -386.497)"
                            fill={colors.darkColor || '#fff'}
                        />
                    </g>
                </g>
                <g id="Group_285" data-name="Group 285" transform="translate(30.406 87.115)">
                    <g id="Group_284" data-name="Group 284">
                        <path
                            id="Path_739"
                            data-name="Path 739"
                            d="M294.825,387.907a1.428,1.428,0,0,0-.023-.152,1.233,1.233,0,0,0-.038-.148c-.015-.049-.032-.1-.051-.144s-.042-.094-.066-.139a1.534,1.534,0,0,0-.17-.255,1.332,1.332,0,0,0-.1-.114,1.31,1.31,0,0,0-.114-.1,1.49,1.49,0,0,0-.124-.092,1.462,1.462,0,0,0-.131-.078c-.045-.024-.091-.046-.139-.066a1.394,1.394,0,0,0-.144-.051,1.163,1.163,0,0,0-.148-.038,1.545,1.545,0,0,0-.46-.023,1.512,1.512,0,0,0-.151.023,1.249,1.249,0,0,0-.149.038,1.413,1.413,0,0,0-.144.051c-.047.02-.094.042-.139.066s-.089.05-.131.078a1.325,1.325,0,0,0-.123.092c-.04.032-.078.067-.115.1a1.337,1.337,0,0,0-.1.114,1.724,1.724,0,0,0-.17.255,1.414,1.414,0,0,0-.065.139,1.391,1.391,0,0,0-.052.144c-.015.049-.027.1-.038.148a1.484,1.484,0,0,0-.023.152,1.5,1.5,0,0,0-.007.153,1.521,1.521,0,0,0,.007.154,1.505,1.505,0,0,0,.023.151c.01.05.023.1.038.149a1.478,1.478,0,0,0,.052.144,1.414,1.414,0,0,0,.065.139c.024.045.051.089.079.131a1.338,1.338,0,0,0,.091.123,1.524,1.524,0,0,0,.1.115,1.478,1.478,0,0,0,.115.1,1.3,1.3,0,0,0,.123.091c.043.028.087.055.131.079s.092.046.139.065a1.391,1.391,0,0,0,.144.052c.049.015.1.027.149.038s.1.018.151.023a1.536,1.536,0,0,0,.154.007c.051,0,.1,0,.153-.007s.1-.014.152-.023.1-.023.148-.038a1.391,1.391,0,0,0,.144-.052,1.5,1.5,0,0,0,.139-.065,1.469,1.469,0,0,0,.131-.079,1.518,1.518,0,0,0,.124-.091,1.325,1.325,0,0,0,.114-.1,1.524,1.524,0,0,0,.1-.115,1.348,1.348,0,0,0,.092-.123q.042-.064.078-.131c.024-.045.046-.092.066-.139s.036-.1.051-.144a1.242,1.242,0,0,0,.038-.149,1.5,1.5,0,0,0,.023-.151,1.414,1.414,0,0,0,0-.307Z"
                            transform="translate(-291.706 -386.497)"
                            fill={colors.darkColor || '#fff'}
                        />
                    </g>
                </g>
                <g id="Group_287" data-name="Group 287" transform="translate(40.192 62.128)">
                    <g id="Group_286" data-name="Group 286">
                        <path
                            id="Path_740"
                            data-name="Path 740"
                            d="M387.153,146.784a1.563,1.563,0,0,0-1.563,1.563v4.507a1.563,1.563,0,1,0,3.126,0v-4.507A1.563,1.563,0,0,0,387.153,146.784Z"
                            transform="translate(-385.59 -146.784)"
                            fill={colors.darkColor || '#fff'}
                        />
                    </g>
                </g>
                <g id="Group_289" data-name="Group 289" transform="translate(40.192 57.876)">
                    <g id="Group_288" data-name="Group 288">
                        <path
                            id="Path_741"
                            data-name="Path 741"
                            d="M388.7,107.4a1.517,1.517,0,0,0-.022-.152c-.01-.05-.023-.1-.038-.149s-.032-.1-.052-.144-.042-.093-.066-.138a1.537,1.537,0,0,0-.078-.132c-.028-.042-.059-.083-.091-.123s-.068-.078-.1-.115a1.628,1.628,0,0,0-.369-.273,1.385,1.385,0,0,0-.138-.065,1.507,1.507,0,0,0-.145-.052c-.048-.015-.1-.027-.148-.038s-.1-.018-.152-.023a1.514,1.514,0,0,0-.307,0c-.05.005-.1.014-.151.023s-.1.023-.149.038a1.486,1.486,0,0,0-.144.052,1.4,1.4,0,0,0-.139.065c-.045.024-.089.051-.131.079s-.083.058-.123.091-.078.068-.115.1-.071.075-.1.115-.063.081-.091.123a1.28,1.28,0,0,0-.078.132q-.036.067-.066.138c-.02.047-.036.1-.052.144s-.027.1-.038.149-.018.1-.023.152a1.492,1.492,0,0,0-.007.153,1.526,1.526,0,0,0,.007.154c.005.05.014.1.023.151s.023.1.038.149.032.1.052.144.042.093.066.138a1.313,1.313,0,0,0,.078.132c.028.042.058.083.091.123s.068.078.1.115.075.071.115.1.08.063.123.091.087.054.131.078.092.046.139.066a1.506,1.506,0,0,0,.144.051,1.247,1.247,0,0,0,.149.037,1.482,1.482,0,0,0,.151.023,1.5,1.5,0,0,0,.154.008,1.572,1.572,0,0,0,1.1-.457c.036-.036.071-.075.1-.115s.063-.081.091-.123a1.517,1.517,0,0,0,.078-.132q.036-.067.066-.138c.02-.047.036-.1.052-.144s.027-.1.038-.149a1.511,1.511,0,0,0,.022-.151,1.528,1.528,0,0,0,.008-.154A1.437,1.437,0,0,0,388.7,107.4Z"
                            transform="translate(-385.585 -105.993)"
                            fill={colors.darkColor || '#fff'}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default TruckIcon;
