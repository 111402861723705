import React, { useContext } from 'react';
import { ThemeContext } from '../../context/Theme';

const CollectIcon = (): JSX.Element => {
    const { colors } = useContext(ThemeContext);
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={{ height: 'auto', width: '50px' }}
        >
            <title>shipment-add</title>
            <g>
                <path
                    fill={!colors.darkColor ? '#ffffff' : colors.darkColor}
                    d="M17.25,24c-3.722,0-6.75-3.028-6.75-6.75s3.028-6.75,6.75-6.75S24,13.528,24,17.25S20.972,24,17.25,24z M17.25,12
            C14.355,12,12,14.355,12,17.25s2.355,5.25,5.25,5.25s5.25-2.355,5.25-5.25S20.145,12,17.25,12z"
                />
                <path
                    fill={!colors.darkColor ? '#ffffff' : colors.darkColor}
                    d="M17.25,21c-0.414,0-0.75-0.336-0.75-0.75V18h-2.25c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.25v-2.25
            c0-0.414,0.336-0.75,0.75-0.75S18,13.836,18,14.25v2.25h2.25c0.414,0,0.75,0.336,0.75,0.75S20.664,18,20.25,18H18v2.25
            C18,20.664,17.664,21,17.25,21z"
                />
                <path
                    fill={!colors.darkColor ? '#ffffff' : colors.darkColor}
                    d="M2.25,19.5C1.009,19.5,0,18.491,0,17.25V6.75c0-0.596,0.231-1.156,0.651-1.58l2.777-4.168C3.846,0.375,4.546,0,5.3,0h8.9
            c0.753,0.002,1.452,0.376,1.869,1.002L18.85,5.17c0.42,0.425,0.65,0.984,0.65,1.58v1.5C19.5,8.664,19.164,9,18.75,9
            S18,8.664,18,8.25v-1.5C18,6.336,17.664,6,17.25,6h-15C1.836,6,1.5,6.336,1.5,6.75v10.5C1.5,17.664,1.836,18,2.25,18h6
            C8.664,18,9,18.336,9,18.75S8.664,19.5,8.25,19.5H2.25z M16.599,4.5l-1.778-2.666c-0.139-0.209-0.372-0.334-0.623-0.334H10.5v3
            H16.599z M9,4.5v-3H5.3c-0.251,0-0.485,0.125-0.624,0.334L2.899,4.5H9z"
                />
            </g>
        </svg>
    );
};

export default CollectIcon;
