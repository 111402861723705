import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { Toast, ToastType } from '../../Toast/Toast';

interface PropsInterface {
    showText: boolean
}

const IdentityVerification = (props: PropsInterface): JSX.Element => {
    const { _t } = useContext(CustomerContext);
    if (!props.showText) {
        return <></>;
    }

    return (
        <Toast type={ToastType.danger}>
            <span>
                {_t('Note! This shipment includes items that require identity verification upon receipt')}<br />
                {_t('The recipient must provide a valid proof of identity to prove his/hers identity')}
            </span>
        </Toast>
    );
}

export default IdentityVerification;