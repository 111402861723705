import React, { useContext } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { ThemeContext } from '../../../context/Theme';
import './QRCode.scss';

export interface QrCodeInterface {
    value?: string;
    displayValue?: boolean;
}

export const QrCode = (props: QrCodeInterface): JSX.Element | null => {
    const { lightColor, darkColor } = useContext(ThemeContext).colors;
    const { value } = props;
    if (!value) {
        return null;
    }

    const bgColor = darkColor ? darkColor : '#06d4fe';
    const fgColor = lightColor ? lightColor : '#4a4a49';

    return (
        <div className="qr-code-canvas">
            <QRCode size={200} bgColor={bgColor} fgColor={fgColor} value={value} />
        </div>
    );
};
