import React, { useContext } from 'react';
import Anonymized from './layouts/Anonymized';
import DistributionIsOngoing from './layouts/DistributionIsOngoing';
import DistributionIsNotOngoing from './layouts/DistributionIsNotOngoing';
import ErrorMessage from './components/Error/ErrorMessage';
import NotFound from './layouts/NotFound';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CustomerContext } from './context/Customer';
import { ThemeContext } from './context/Theme';
import { Loader } from './components/Loader/Loader';
import { Helmet } from 'react-helmet';

const LayoutContent = (): JSX.Element => {
    const { status } = useContext(CustomerContext);
    if (status.isUnhandled()) {
        return <DistributionIsOngoing />;
    }
    return <DistributionIsNotOngoing />;
};

const CustomCssStyle = (): JSX.Element | null => {
    const { fontName, fontUrl, colors, hasTheme } = useContext(ThemeContext);
    let customStyle = '';
    if (fontName && fontUrl) {
        customStyle += `
        @font-face {
          font-family: ${fontName};
          src: url(${fontUrl});
        }
        #root {
            font-family: ${fontName};
        }`;
    }

    if (colors.darkColor && hasTheme) {
        customStyle += `
        #root {
            background-color: ${colors.lightColor};
        }`;
    }

    if (!customStyle) {
        return null;
    }

    return (
        <Helmet>
            <style>{customStyle}</style>
        </Helmet>
    );
};

const CustomerSupportInjectionScript = (): JSX.Element | null => {
    const { customerSupportMarkup } = useContext(CustomerContext);

    if (!customerSupportMarkup || customerSupportMarkup === 'undefined') {
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: customerSupportMarkup }} />;
};

const Router = (): JSX.Element => {
    const { error, hasLoadedFromServer, anonymized } = useContext(CustomerContext);

    if (error.any) {
        return (
            <NotFound>
                <ErrorMessage message={error.message} />
            </NotFound>
        );
    }

    if (!hasLoadedFromServer) {
        return <Loader />;
    }

    if (anonymized) {
        return <Anonymized />;
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path="/:hash"
                    component={() => (
                        <>
                            <LayoutContent />
                            <CustomerSupportInjectionScript />
                            <CustomCssStyle />
                        </>
                    )}
                />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
