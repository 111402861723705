import React, { useContext, useState } from 'react';
import axios from 'axios';
import { apiHandleBlobEndpoint } from '../../../utils/api';
import SignatureCanvas from 'react-signature-canvas';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';
import { Note, Title, Row } from '../../Customer/Layout';
import './Signature.scss';
import { TYPES } from '../../Pods/Pod';
import { SaveButton } from '../../Button/SaveButton';

const type = 'image/jpeg';
let sigCanvas = {};

const postBlob = (requestData, hash) => {
    axios.post(`${apiHandleBlobEndpoint(hash)}`, requestData).catch((e) => console.error(e));
};

/**
 * Component "Signature" can not be a typescript component
 * du to 3d part developer support for package "react-signature-canvas"
 */
export const Signature = () => {
    const {
        _t,
        hash,
        settings: { digitalSignatures },
        receivedBy,
        pods,
    } = useContext(CustomerContext);
    const theme = useContext(ThemeContext);

    const blobName = `customer-blob-${hash}`;
    const receivedByName = `customer-received-by-${hash}`;

    const blob = pods.find((p) => p.type === TYPES.SIGNATURE) || null;
    const blobData = blob ? blob.url : localStorage.getItem(blobName) || null;
    const hasBlob = blobData !== null;

    const [state, setState] = useState({
        blobData: blobData,
        showSaveButton: !hasBlob,
        hasBlob: hasBlob,
        recipient: receivedBy || localStorage.getItem(receivedByName) || '',
    });

    const onSave = () => {
        if (sigCanvas.isEmpty()) {
            return;
        }

        const hasSignedForOrder = window.confirm(_t('Are you sure you want to sign for your goods?'));
        if (hasSignedForOrder) {
            const canvasData = sigCanvas.toDataURL(type, 0.5);
            const [fileType, imageData] = canvasData.split(',');
            const data = {
                blob: imageData,
                recipient: state.recipient,
                type: fileType,
            };

            localStorage.setItem(blobName, canvasData);
            localStorage.setItem(receivedByName, data.recipient);

            setState({
                blobData: canvasData,
                showSaveButton: false,
                recipient: state.recipient,
            });
            postBlob(data, hash);
        }
    };

    const onInputChange = (value) => {
        setState({
            blobData: state.blobData,
            showSaveButton: state.showSaveButton,
            recipient: value,
        });
    };

    const displaySignatureCanvas = () => {
        const canvasProps = {
            width: 500,
            height: 150,
            className: 'sig-canvas',
        };

        if (!state.showSaveButton || !digitalSignatures || state.blobData !== null) {
            return (
                <>
                    <img className="sig-canvas" style={{ height: 'auto' }} alt="blob" src={state.blobData} />
                    {state.recipient ? (
                        <input className="printed-name" disabled="disabled" value={state.recipient} />
                    ) : null}
                </>
            );
        }

        if (!digitalSignatures) {
            return null; // no blob to show
        }

        return (
            <div>
                <SignatureCanvas
                    backgroundColor={'#ffffff'}
                    penColor={theme.colors.darkColor || '#4a4a49'}
                    canvasProps={canvasProps}
                    clearOnResize={true}
                    ref={(ref) => {
                        sigCanvas = ref;
                    }}
                />

                <input
                    value={state.recipient}
                    onKeyUp={(e) => onInputChange(e.target.value)}
                    onChange={(e) => onInputChange(e.target.value)}
                    className="printed-name"
                    type="search"
                    placeholder={_t('Name in block letters')}
                />

                <SaveButton
                    onClickFunction={onSave}
                    show={state.showSaveButton}
                    title={_t('Save signature')}
                />
            </div>
        );
    };

    if (!digitalSignatures && state.blobData === null) {
        return null;
    }

    return (
        <Row>
            <div className="blob-title-wrapper">
                <Title title={_t('Signature')} />
                {state.showSaveButton ? (
                    <button onClick={() => sigCanvas.clear()}>{_t('Clear')}</button>
                ) : null}
            </div>

            {displaySignatureCanvas()}

            {!hasBlob ? <Note text={_t('You can choose to sign for your goods here instead of on the driver\'s phone.')} /> : <></> }
        </Row>
    );
};
