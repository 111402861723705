import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';

export const EstimatedDeliveryTime = (): JSX.Element => {
    const { deliveryTimeInfo, settings, status, _t } = useContext(CustomerContext);

    const ForHumans = (): JSX.Element => {
        const { dayForHumans, dateForHumans } = deliveryTimeInfo;
        return (
            <span>
                <strong>{dayForHumans}</strong>
                <small>{dateForHumans}</small>
            </span>
        );
    };

    const SubTitle = () => {
        if (status.isCancelled()) {
            return <small>{_t('Sub title for cancelled')}</small>;
        }

        if (status.isNotDelivered()) {
            return <small>{_t('Sub title for not delivered')}</small>;
        }

        if (status.isDelivered()) {
            return <small>{_t('Sub title for delivered')}</small>;
        }

        if (status.isDeliveredNotHome()) {
            return <small>{_t('Sub title for not home')}</small>;
        }

        return <small>{_t('Sub title for unhandled')}</small>;
    }

    const EstimatedDelivery = (): JSX.Element => {
        const { estimatedDeliveryTime, estimatedDeliveryTimeSpan, timeWindow } = deliveryTimeInfo;
        if (settings.showEstimatedDeliveryTime) {
            return (
                <span>
                    <strong>{estimatedDeliveryTime}</strong>
                    <small>{timeWindow}</small>
                </span>
            );
        }

        return (
            <span>
                <strong>{estimatedDeliveryTimeSpan}</strong>
                <SubTitle />
            </span>
        );
    };

    return (
        <div className="estimated-delivery-time">
            <ForHumans />
            <EstimatedDelivery />
        </div>
    );
};
