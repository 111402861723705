import React, { useContext } from 'react';
import * as L from 'leaflet';
import { Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import deliveryTruckFront from '../../../images/deliveryTruck-front.svg';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';

const TruckTile = (): JSX.Element | null => {
    const { driverLocation, isDelivery, status } = useContext(CustomerContext);
    const { truckPin, hasTheme } = useContext(ThemeContext);
    const { lat, lng } = driverLocation;

    if (status.isUnhandled() && isDelivery) {
        const icon = L.icon({
            iconUrl: hasTheme && truckPin ? truckPin : deliveryTruckFront,
            iconSize: [64, 78],
            iconAnchor: [32, 78],
            className: 'truck-icon',
        });

        return <Marker position={[lat, lng]} icon={icon}></Marker>;
    }

    return null;
};

export default TruckTile;
