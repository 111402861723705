import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import { SubTitle, Title, Row } from '../Customer/Layout';

export const TrackingNumber = (): JSX.Element | null => {
    const { externalId, settings, _t } = useContext(CustomerContext);

    if (!settings.showTrackingNumber || !externalId) {
        return null;
    }

    return (
        <Row>
            <Title title={_t('Tracking number')} />
            <SubTitle title={externalId} />
        </Row>
    );
};
