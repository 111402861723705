import React, { useContext } from 'react';
import { Row } from '../Customer/Layout';
import { CustomerContext } from '../../context/Customer';
import { PodPhoto, TYPES } from './Pod';
import './Pods.scss';

export const Pods = (): JSX.Element => {
    const { pods } = useContext(CustomerContext);
    const photos = pods.filter(p => p.type === TYPES.PHOTO);
    if (photos.length > 0) {
        return (
            <Row>
                <div className="pods-wrapper">
                    {photos.map((photo, key) => <PodPhoto key={key} url={photo.url} />)}
                </div>
            </Row>
        );
    }
    return <></>;
};
