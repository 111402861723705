import React, { useContext } from 'react';
import { ThemeContext } from '../../context/Theme';

const getFill = (color?: string): string => {
    if (!color) {
        return '#06d4fe';
    }

    return color;
};

const iconSyle = { height: 'auto', width: '25px' };

export const Bicycle = (): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={iconSyle}
        >
            <title>bicycle</title>
            <g>
                <path
                    fill={getFill(darkColor)}
                    d="M19.5,21.286c-2.481,0-4.5-2.019-4.5-4.5c0-1.502,0.759-2.905,2-3.737l-1.249-2.782l-6.79,1.842l-1.197,1.59
                C8.553,14.532,9,15.64,9,16.786c0,2.481-2.019,4.5-4.5,4.5S0,19.267,0,16.786s2.019-4.5,4.5-4.5c0.719,0,1.427,0.175,2.067,0.509
                l1.054-1.4L5.564,7.786H3.75C3.336,7.786,3,7.45,3,7.036s0.336-0.75,0.75-0.75H9c0.414,0,0.75,0.336,0.75,0.75S9.414,7.786,9,7.786
                H7.291l1.59,2.79l6.249-1.695l-1.3-2.897c-0.339-0.753-0.002-1.642,0.751-1.982c0.059-0.026,0.12-0.049,0.181-0.068l2.987-0.901
                c0.07-0.021,0.142-0.032,0.216-0.032c0.328,0,0.624,0.219,0.719,0.533c0.058,0.192,0.038,0.395-0.057,0.572
                c-0.095,0.176-0.253,0.306-0.445,0.363l-2.984,0.9l3.171,7.064c0.373-0.098,0.752-0.148,1.131-0.148c2.481,0,4.5,2.019,4.5,4.5
                S21.981,21.286,19.5,21.286z M17.629,14.448c-0.705,0.566-1.129,1.429-1.129,2.338c0,1.654,1.346,3,3,3s3-1.346,3-3s-1.346-3-3-3
                c-0.169,0-0.338,0.014-0.505,0.043l1.189,2.65c0.082,0.183,0.088,0.386,0.017,0.574c-0.071,0.187-0.211,0.336-0.394,0.418
                c-0.096,0.043-0.2,0.066-0.307,0.066c-0.295,0-0.564-0.174-0.685-0.443L17.629,14.448z M4.5,13.786c-1.654,0-3,1.346-3,3
                s1.346,3,3,3s3-1.346,3-3c0-0.688-0.23-1.337-0.656-1.866l-1.745,2.317c-0.141,0.187-0.365,0.299-0.599,0.299
                c-0.164,0-0.319-0.052-0.451-0.151c-0.16-0.121-0.264-0.296-0.292-0.495c-0.028-0.198,0.023-0.396,0.144-0.556l1.745-2.318
                C5.283,13.865,4.893,13.786,4.5,13.786z"
                />
            </g>
        </svg>
    );
};

export const Car = (): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={iconSyle}
        >
            <title>car-1</title>
            <g>
                <path
                    fill={getFill(darkColor)}
                    d="M20.25,20.75c-1.241,0-2.25-1.009-2.25-2.25v-0.75H6v0.75c0,1.241-1.009,2.25-2.25,2.25S1.5,19.741,1.5,18.5v-0.879
                C0.615,17.308,0,16.46,0,15.5v-2.251c0-0.358,0.074-0.706,0.22-1.036c0.004-0.01,0.016-0.036,0.029-0.061
                c0.119-0.257,0.291-0.503,0.5-0.713l1.94-1.94l-0.75-0.75H0.75C0.336,8.75,0,8.414,0,8s0.336-0.75,0.75-0.75h1.5
                c0.2,0,0.389,0.078,0.53,0.22l0.676,0.676l1.291-3.449C5.184,3.532,6.313,2.75,7.556,2.75h8.885c1.243,0,2.372,0.782,2.808,1.947
                l1.292,3.451L21.22,7.47c0.14-0.14,0.333-0.22,0.53-0.22h1.5C23.664,7.25,24,7.586,24,8s-0.336,0.75-0.75,0.75h-1.189l-0.75,0.75
                l1.94,1.94c0.21,0.21,0.382,0.457,0.51,0.732c0.009,0.018,0.018,0.037,0.026,0.057C23.926,12.541,24,12.89,24,13.249V15.5
                c0,0.96-0.615,1.808-1.5,2.121V18.5C22.5,19.741,21.491,20.75,20.25,20.75z M19.5,18.5c0,0.414,0.336,0.75,0.75,0.75
                S21,18.914,21,18.5v-0.75h-1.5V18.5z M3,18.5c0,0.414,0.336,0.75,0.75,0.75S4.5,18.914,4.5,18.5v-0.75H3V18.5z M1.5,15.5
                c0,0.414,0.336,0.75,0.75,0.75h19.5c0.414,0,0.75-0.336,0.75-0.75v-2.25h-3.129c-0.2,0-0.388,0.078-0.53,0.219L17.78,14.53
                c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22S16.5,14.2,16.5,14s0.078-0.389,0.22-0.53l1.061-1.061
                c0.425-0.425,0.99-0.659,1.59-0.659h2.068l-1.5-1.5H4.061l-1.5,1.5h2.068c0.601,0,1.166,0.234,1.59,0.659L7.28,13.47
                C7.422,13.611,7.5,13.8,7.5,14s-0.078,0.389-0.22,0.53c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22l-1.061-1.061
                c-0.139-0.139-0.332-0.219-0.53-0.219H1.5V15.5z M19.166,8.75l-1.321-3.527c-0.218-0.582-0.782-0.973-1.404-0.973H7.557
                c-0.622,0-1.186,0.391-1.404,0.973L4.832,8.75H19.166z"
                />
            </g>
        </svg>
    );
};

export const RailRoad = (): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={iconSyle}
        >
            <title>railroad-metro</title>
            <g>
                <path
                    fill={getFill(darkColor)}
                    d="M0.75,24.013c-0.181,0-0.355-0.065-0.491-0.184c-0.312-0.272-0.346-0.746-0.074-1.058l2.37-2.729
                c0.143-0.164,0.349-0.258,0.566-0.258c0.18,0,0.355,0.065,0.491,0.183c0.313,0.272,0.346,0.747,0.075,1.059l-2.37,2.729
                C1.174,23.919,0.968,24.013,0.75,24.013z"
                />
                <path
                    fill={getFill(darkColor)}
                    d="M23.25,24.013c-0.218,0-0.424-0.094-0.567-0.258l-2.37-2.729c-0.271-0.312-0.238-0.787,0.075-1.058
                c0.137-0.119,0.311-0.184,0.492-0.184c0.218,0,0.424,0.094,0.566,0.259l2.37,2.729c0.271,0.312,0.238,0.787-0.075,1.058
                C23.605,23.948,23.431,24.013,23.25,24.013z"
                />
                <path
                    fill={getFill(darkColor)}
                    d="M11.953,22.511c-1.062-0.005-2.114-0.228-3.118-0.664l-0.164-0.072c-0.045-0.02-0.09-0.04-0.135-0.061
                c-1.172-0.538-2.231-1.346-3.056-2.335c-0.004-0.005-0.008-0.01-0.012-0.015C3.063,16.725,1.5,12.656,1.5,9.013
                c0-5.551,4.023-9,10.5-9c6.477,0,10.5,3.449,10.5,9c0,3.638-1.558,7.7-3.972,10.357c-0.005,0.006-0.009,0.012-0.014,0.017
                c-0.82,0.982-1.878,1.79-3.055,2.33c-0.042,0.02-0.091,0.042-0.139,0.063l-0.158,0.069c-0.999,0.434-2.052,0.657-3.123,0.662
                c0,0-0.026,0.001-0.029,0.001C12.009,22.513,11.961,22.512,11.953,22.511z M6.88,18.689c0.567,0.581,1.175,1.059,1.808,1.424
                c0.007,0.004,0.015,0.009,0.022,0.014l0.103,0.056c0.979,0.516,2.085,0.804,3.195,0.83c1.091-0.026,2.198-0.314,3.181-0.833
                c0.677-0.371,1.326-0.873,1.93-1.491c-0.537-1.681-2.691-2.926-5.12-2.926C9.57,15.764,7.417,17.008,6.88,18.689z M12,14.264
                c2.685,0,5.124,1.269,6.184,3.153c0.135-0.186,0.266-0.377,0.392-0.572C20.071,14.534,21,11.533,21,9.013c0-4.696-3.365-7.5-9-7.5
                s-9,2.804-9,7.5c0,2.52,0.929,5.521,2.425,7.831c0.126,0.195,0.257,0.386,0.391,0.572C6.876,15.533,9.315,14.264,12,14.264z"
                />
                <path
                    fill={getFill(darkColor)}
                    d="M8.25,10.513c-1.654,0-3-1.346-3-3s1.346-3,3-3h7.5c1.654,0,3,1.346,3,3s-1.346,3-3,3H8.25z M8.25,6.013
                c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5h7.5c0.827,0,1.5-0.673,1.5-1.5s-0.673-1.5-1.5-1.5H8.25z"
                />
            </g>
        </svg>
    );
};

export const Walking = (): JSX.Element => {
    const { darkColor } = useContext(ThemeContext).colors;
    return (
        <svg
            version="1.1"
            id="Regular"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={iconSyle}
        >
            <title>single-neutral</title>
            <g>
                <path
                    fill={getFill(darkColor)}
                    d="M12,7.5c-2.068,0-3.75-1.682-3.75-3.75S9.932,0,12,0c2.068,0,3.75,1.682,3.75,3.75S14.068,7.5,12,7.5z M12,1.5
                c-1.241,0-2.25,1.009-2.25,2.25S10.759,6,12,6c1.241,0,2.25-1.009,2.25-2.25S13.241,1.5,12,1.5z"
                />
                <path
                    fill={getFill(darkColor)}
                    d="M9.75,24c-0.387,0-0.708-0.29-0.746-0.675L8.321,16.5H6.75C6.336,16.5,6,16.164,6,15.75V13.5c0-3.308,2.692-6,6-6
                s6,2.692,6,6v2.25c0,0.414-0.336,0.75-0.75,0.75h-1.571l-0.683,6.825C14.958,23.709,14.637,24,14.25,24H9.75z M13.571,22.5
                l0.683-6.825C14.292,15.291,14.613,15,15,15h1.5v-1.5c0-2.481-2.019-4.5-4.5-4.5c-2.481,0-4.5,2.019-4.5,4.5V15H9
                c0.387,0,0.708,0.29,0.746,0.675l0.682,6.825H13.571z"
                />
            </g>
        </svg>
    );
};
