import React, { useContext, useState } from 'react';
import axios from 'axios';
import { apiRemoveBoxKey } from '../../utils/api';
import { CustomerContext } from '../../context/Customer';
import { Row, Note } from '../Customer/Layout';
import eDropLogo from '../../images/e-drop_logo.png';
import OngoingRequest from '../OngoingRequest';
import { EDropBtn } from './EDropBtn';
import './EDrop.scss';

export const EDrop = (): JSX.Element | null => {
    const { status, hash, hasBoxKey, _t } = useContext(CustomerContext);
    const [state, setState] = useState({ ongoingRequest: false, hasBoxKey });

    const optOut = async () => {
        setState({ ongoingRequest: true, hasBoxKey });

        try {
            const response = await axios.get(apiRemoveBoxKey(hash));

            /*eslint camelcase: ["error", {allow: ["response_data"]}]*/
            const { message } = response.data.response_data;
            if (message) {
                setState({ ongoingRequest: false, hasBoxKey: false });
                alert(message);
            }
        } catch (e) {
            console.error(e);
            setState({ ongoingRequest: false, hasBoxKey });
            alert(e);
        }
    };

    const renderOptOutBtn = () => {
        if (status.isHandled()) {
            return null;
        }

        if (state.ongoingRequest) {
            return <OngoingRequest />;
        }

        return <EDropBtn handleOnClick={optOut} />;
    };

    if (!state.hasBoxKey) {
        return null;
    }

    return (
        <Row>
            <div className="eDropLogo">
                <img src={eDropLogo} alt="e-drop logo" />
            </div>
            <p>
                <a className="e-drop-link" rel="noopener noreferrer" target="_blank" href="https://www.e-drop.se/">
                    https://e-drop.se
                </a>
                <br />
                {_t('The goods will be delivered to your E-drop cabinet where they will be stored safely until picking them up')}
            </p>
            <Note text={_t('The goods will be delivered to the cabinet. You can opt out of this delivery method, in which case a standard delivery will be carried out.')} />
            {renderOptOutBtn()}
        </Row>
    );
};
