import React from 'react';
import './PackageIcons.scss';

export const PackageNotDeliveredIcon = (): JSX.Element => {
    return (
        <svg className="default-icon-style" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
            <g>
                <path
                    className="package-icon-not-delivered"
                    d="M500,990C229.4,990,10,770.6,10,500S229.4,10,500,10c270.6,0,490,219.4,490,490S770.6,990,500,990L500,990z M786.3,500.3c0-22.6-18.3-20.4-40.9-20.4H275.6c-22.6,0-40.9-2.1-40.9,20.4v20.4c0,22.6,18.3,20.4,40.9,20.4h469.8c22.6,0,40.9,2.1,40.9-20.4V500.3L786.3,500.3z"
                />
            </g>
        </svg>
    );
};

export const PackageDeliveredIcon = (): JSX.Element => {
    return (
        <svg className="default-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                className="package-icon-delivered"
                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            />
        </svg>
    );
};
