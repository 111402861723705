import React, { Component, CSSProperties } from 'react';
import GoogleMapReact from 'google-map-react';
import '../Map.scss';
import deliveryTruckFront from '../../../images/deliveryTruck-front.svg';
import { googleMapsApiKey } from '../../../utils/api';
import { CustomerConsumer } from '../../../context/Customer';
import CustomerTile from './CustomerTile';
import TruckTile from './TruckTile';

declare global {
    interface Window {
        google: any;
    }
}

window.google = window.google || {};

let deliveryTruckMarker = {}; // eslint-disable-line

interface MapOptions {
    mapTypeControl: boolean;
    scrollwheel: boolean;
    panControl: boolean;
    streetViewControl: boolean;
    scaleControl: boolean;
    zoomControl: boolean;
    rotateControl: boolean;
    fullscreenControl: boolean;
}

/**
 * @return {MapOptions}
 */
const mapOptions = (): MapOptions => {
    return {
        mapTypeControl: false,
        scrollwheel: false,
        panControl: false,
        streetViewControl: false,
        scaleControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
    };
};

const mapStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 0,
};

export default class GoogleMap extends Component {
    private setBounds(
        map: any,
        customerLat: number,
        customerLng: number,
        driverLat: number | null,
        driverLng: number | null,
    ): void {
        if (!driverLat || !driverLng) {
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();

        bounds.extend(
            new window.google.maps.LatLng({
                lat: customerLat,
                lng: customerLng,
            }),
        );

        bounds.extend(
            new window.google.maps.LatLng({
                lat: driverLat,
                lng: driverLng,
            }),
        );

        deliveryTruckMarker = new window.google.maps.MarkerImage(
            deliveryTruckFront,
            null,
            null,
            null,
            new window.google.maps.Size(14, 14),
        );

        map.fitBounds(bounds);
        map.setZoom(map.getZoom() - 1);
    }

    public render(): JSX.Element {
        return (
            <CustomerConsumer>
                {(customer) => (
                    <div style={mapStyle}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                language: customer.locale,
                                key: googleMapsApiKey(),
                            }}
                            center={{
                                lat: customer.address.lat,
                                lng: customer.address.lng,
                            }}
                            defaultZoom={10}
                            options={mapOptions}
                            onGoogleApiLoaded={({ map }) => {
                                if (!customer.status.isUnhandled() && customer.isCollect) {
                                    return;
                                }
                                this.setBounds(
                                    map,
                                    customer.address.lat,
                                    customer.address.lng,
                                    customer.driverLocation.lat,
                                    customer.driverLocation.lng,
                                );
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            resetBoundsOnResize={true}
                        >
                            <CustomerTile lat={customer.address.lat} lng={customer.address.lng} />
                            <TruckTile lat={customer.driverLocation.lat} lng={customer.driverLocation.lng} />
                        </GoogleMapReact>
                    </div>
                )}
            </CustomerConsumer>
        );
    }
}
