import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';

export const CollectOrder = (): JSX.Element => {
    const { date, timeWindow } = useContext(CustomerContext).deliveryTimeInfo;
    return (
        <div className="estimated-delivery-time">
            <span>
                <strong>{date}</strong>
                <small>{timeWindow}</small>
            </span>
        </div>
    );
};
