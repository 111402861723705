import React, { useContext } from 'react';
import { CustomerContext } from '../../context/Customer';
import CancelButton from './CancelButton';
import Moment from 'react-moment';

interface ProposeDaysPropsInterface {
    handleCancel(date?: string): void;
}

interface CancelButtonPropsInterface extends ProposeDaysPropsInterface {
    date?: string;
    locale: string;
}

const CancelButtonWithProposeDay = (props: CancelButtonPropsInterface): JSX.Element | null => {
    const { date, locale, handleCancel } = props;
    if (!date) {
        return null;
    }

    return (
        <div>
            <button type="button" className="cancel-delivery-btn" onClick={() => handleCancel(date)}>
                <Moment date={date} locale={locale} format="DD MMMM" />
            </button>
        </div>
    );
};

const ProposeDays = (props: ProposeDaysPropsInterface): JSX.Element => {
    const { locale, proposeDays, _t } = useContext(CustomerContext);
    const hasProposeDays = proposeDays.length > 0;

    if (!hasProposeDays) {
        return <CancelButton title={_t('Next best occasion')} handleCancel={props.handleCancel} />;
    }

    return (
        <>
            {proposeDays.map((proposeDay, key) => (
                <CancelButtonWithProposeDay
                    date={proposeDay.date}
                    key={key}
                    locale={locale}
                    handleCancel={props.handleCancel}
                />
            ))}
        </>
    );
};

export default ProposeDays;
