import React from 'react';
import icon from '../../../images/BankID_logo.png';
import { Button } from '../../Button/Button';

const BankIdIcon = (props: { alt: string }): JSX.Element => {
    const { alt } = props;
    return <img alt={alt} src={icon} />;
};

export interface VerifyButtonPropsInterface {
    onClickFunction: CallableFunction,
    title: string,
}

export const VerifyButton = (props: VerifyButtonPropsInterface): JSX.Element => {
    const { onClickFunction, title } = props;

    return (
        <div>
            <Button
                onClickFunction={onClickFunction}
                show={true}
                title={title}
            >
                <BankIdIcon alt={title} />
            </Button>
        </div>
    )
}