import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';

interface ShowAll {
    (showAll: boolean): void;
}
interface ShowAllBtnProps {
    showBtn: boolean;
    showAll: boolean;
    setShowAll: ShowAll;
}

const ShowAllBtn = (props: ShowAllBtnProps): JSX.Element | null => {
    const { _t } = useContext(CustomerContext);
    const { darkColor } = useContext(ThemeContext).colors;
    const { showBtn, showAll, setShowAll } = props;

    if (!showBtn) {
        return null;
    }

    const title = showAll ? _t('Show less') : _t('Show more');
    return (
        <div className="show-all-statuses">
            <span style={{ color: darkColor }} role="button" onClick={() => setShowAll(!showAll)}>
                {title}
            </span>
        </div>
    );
};

export default ShowAllBtn;
