interface UserAgentInterface {
    android: boolean,
    iOS: boolean
}

export const userAgent = (): UserAgentInterface => {
    const userAgent: string = navigator.userAgent || (window as any).opera || undefined;

    const android = userAgent.indexOf('Android') >= 0 && userAgent.indexOf('Chrome');

    const ios = !android && userAgent.indexOf('iPhone') >= 0 && userAgent.indexOf('Safari');

    return {
        android: Boolean(android).valueOf(),
        iOS: Boolean(ios).valueOf(),
    };
}