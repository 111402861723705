import React, { useContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { apiStartBankId, apiBankIdCallback } from '../../../utils/api';
import { CustomerContext } from '../../../context/Customer';
import OngoingRequest from '../../OngoingRequest';
import AgeVerification from './AgeVerification';
import { getEnv } from '../../../utils/config';
import VerifyOnOtherDeviceQrCode from './VerifyOnOtherDeviceQrCode';
import { defaultTokens } from './Tokens';
import { userAgent } from './UserAgent';
import { VerifyButtons } from './VerifyButtons';
import { Row } from '../Layout';
import IdentityVerification from './IdentityVerification';
import './Identification.scss';
import { VerificationCode } from './VerificationCode';
import { Toast, ToastType } from '../../Toast/Toast';

const isDebugMode = getEnv('REACT_APP_IS_DEVELOPMENT', false);

interface StartVerifyRequestResponseInterface {
    response: AxiosResponse,
    transTime: number,
}

export const Identification = (): JSX.Element => {
    /*eslint camelcase: ["error",
        {allow:
            [
                "response_data",
                "verification_code",
                "do_identity_verification",
                "do_age_verification"
            ]
        }
    ]*/
    const c = useContext(CustomerContext);
    const { _t, settings, hash, } = c;
    const [verificationCode, setVerificationCode] = useState(c.verificationCode || '');
    const [ongoingRequest, setOngoingRequest] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [toastType, setToastType] = useState(ToastType.default);
    const [identificationIsInProcess, setIdentificationIsInProcess] = useState(false);
    const [doAgeVerification, setDoAgeVerification] = useState(settings.doAgeVerification);
    const [doIdentityVerification, setDoIdentityVerification] = useState(settings.doIdentityVerification);
    const [showText, setShowText] = useState({ showIdentityVerificationText: doIdentityVerification, showAgeVerificationText: !doIdentityVerification && doAgeVerification });
    const [tokens, setTokens] = useState(defaultTokens);
    const [time, setTime] = useState(-1);

    const displayMessageFromApiResponse = (message?: any, type?: ToastType) => {
        if (message) {
            setToastType(type || ToastType.success);
            setResponseMessage(message);
            setTimeout(() => {
                setResponseMessage(undefined);
                setToastType(ToastType.default);
            }, 7000);
        }
    }

    const resetState = (ongoingRequest: boolean, identificationInProcess: boolean) => {
        setOngoingRequest(ongoingRequest);
        setIdentificationIsInProcess(identificationInProcess);
        setTokens(defaultTokens);
        setTime(-1);
    }

    const identificationWasChecked = async (response: AxiosResponse<any, any>) => {
        const data = await response.data.response_data;
        resetState(false, false);
        if (!data || response.data.error) {
            identificationCheckFailed(response.data.message)
            return;
        }

        const {
            verification_code,
            settings: { do_identity_verification, do_age_verification }
        } = data;

        setVerificationCode(verification_code);
        setDoIdentityVerification(do_identity_verification);
        setDoAgeVerification(do_age_verification);
        displayMessageFromApiResponse(response.data.message);
        const showTextUpdated = {
            showIdentityVerificationText: do_identity_verification,
            showAgeVerificationText: !do_identity_verification && do_age_verification,
        };
        setShowText(showTextUpdated);
    }

    const identificationCheckFailed = (reason: any) => {
        console.error(reason);
        resetState(false, false);
        displayMessageFromApiResponse(reason, ToastType.warning);
    }

    const startVerifyRequest = async (): Promise<StartVerifyRequestResponseInterface> => {
        resetState(true, true);
        const startTime = Date.now();
        const response = await axios.get(apiStartBankId(hash));
        const completedTime = Date.now();
        setOngoingRequest(false);
        let transTime = (completedTime - startTime) / 1000;
        if (isDebugMode) {
            console.log('transTime:' + transTime);
        }

        return { response, transTime};
    };

    const verifyOnSameDevice = async () => {
        try {
            const { response } = await startVerifyRequest();
            if (response.data.error) {
                alert('Error: ' + response.data.message);
            } else {
                const { orderRef, autoStartToken, qrStartToken, qrStartSecret } = response.data.response_data;
                setTokens({ orderRef, autoStartToken, qrStartToken, qrStartSecret });

                const { iOS, android} = userAgent();
                const url = (android || iOS) ?
                    "https://app.bankid.com/?autostarttoken=" + autoStartToken + "&redirect=null" :
                    "bankid:///?autostarttoken=" + autoStartToken + "&redirect=null";

                axios
                    .get(apiBankIdCallback(hash, orderRef))
                    .then(identificationWasChecked)
                    .catch(identificationCheckFailed);
                window.location.href = url;
            }
        } catch (e) {
            console.error(e);
            resetState(false, false);
            alert(e);
        }
    }

    const verifyOnOtherDevice = async () => {
        try {
            const { response, transTime } = await startVerifyRequest();
            if (response.data.error) {
                alert('Error: ' + response.data.message);
            } else {
                const { orderRef, autoStartToken, qrStartToken, qrStartSecret, time } = response.data.response_data;
                const dateTime = Date.parse(time);
                const currTime = Date.now();
                let diffSecs = (currTime - dateTime) / 1000;
                if (isDebugMode) {
                    console.log('diffsecs Server -> Client:' + diffSecs);
                }

                const timeOffset = Math.floor(transTime / 3);  // Heuristics
                setTokens({ orderRef, autoStartToken, qrStartToken, qrStartSecret });
                if (isDebugMode) {
                    console.log('offset:' + timeOffset);
                }

                const newTime = timeOffset;
                setTime(newTime);
                axios
                    .get(apiBankIdCallback(hash, orderRef))
                    .then(identificationWasChecked)
                    .catch(identificationCheckFailed);
            }
        } catch (e) {
            console.error(e);
            resetState(false, false);
            alert(e);
        }
    }

    if (ongoingRequest) {
        return <OngoingRequest />;
    }

    return (
        <Row>
            <div className="identification-wrapper">
                <div>
                    <VerifyOnOtherDeviceQrCode time={time} tokens={tokens} setTime={setTime} />
                    <VerificationCode verificationCode={verificationCode} useBankIdQrCode={settings.useBankIdQrCode} />
                </div>
                <div className="identification-alerts">
                    <div>
                        <Toast type={toastType} message={responseMessage} />
                    </div>
                    <div>
                        <IdentityVerification showText={showText.showIdentityVerificationText} />
                    </div>
                    <div>
                        <AgeVerification showText={showText.showAgeVerificationText} />
                    </div>
                </div>
                <div className="identification-buttons">
                    <VerifyButtons
                        display={(doAgeVerification || doIdentityVerification) && !identificationIsInProcess}
                        sameDevice={{ onClickFunction: verifyOnSameDevice, title: _t('Verify on same device') }}
                        otherDevice={{ onClickFunction: verifyOnOtherDevice, title: _t('Verify on other device') }}
                    />
                </div>
            </div>
        </Row>
    );
}