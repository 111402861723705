import React, { useContext } from 'react';
import pindeliverLogo from '../../images/pinDeliver_logo_liggande_svart.png';
import './SenderLogoCard.scss';
import { CustomerContext } from '../../context/Customer';
import { ThemeContext } from '../../context/Theme';
import { CustomerServiceInterface } from '../Customer/CustomerService';

export interface SenderInterface extends CustomerServiceInterface {
    name: string;
}

interface SenderLogoCardProps {
    backgroundColor: string | null;
}

export const SenderLogoCard = (props: SenderLogoCardProps): JSX.Element => {
    const { hasTheme, logo } = useContext(ThemeContext);
    const { name } = useContext(CustomerContext).sender;

    const style = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {};
    return (
        <div style={style} className="sender-logo">
            <img alt={name} src={hasTheme && logo !== null ? logo : pindeliverLogo} />
        </div>
    );
};
