import React, { useContext } from 'react';
import { ThemeContext } from '../../context/Theme';

const CloseIcon = (): JSX.Element => {
    const { colors } = useContext(ThemeContext);
    return (
        <svg className="close-icon" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path
                d="M62.146 49.999L92.364 19.78c1.159-1.159 1.159-3.037 0-4.195l-7.95-7.95c-1.158-1.158-3.036-1.158-4.195 0L50 37.854 19.781 7.635c-1.158-1.158-3.037-1.158-4.196 0l-7.95 7.95c-1.159 1.158-1.159 3.036 0 4.195l30.219 30.219-30.218 30.22c-1.16 1.159-1.16 3.037 0 4.195l7.949 7.95c1.159 1.159 3.038 1.159 4.196 0L50 62.146l30.218 30.218c1.159 1.159 3.037 1.159 4.195 0l7.95-7.95c1.159-1.158 1.159-3.036 0-4.195l-30.217-30.22z"
                fill={colors.darkColor || '#fff'}
            />
        </svg>
    );
};

export default CloseIcon;
