import { DriverNoteInterface } from '../components/Customer/DriverNote/DriverNote';
import { AddressInterface } from '../components/Customer/Address';
import { LocationInterface } from '../components/Map/Location';
import { ErrorInterface } from '../components/Error/ErrorMessage';
import { SenderInterface } from '../components/Sender/SenderLogoCard';
import { PackageInterface } from '../components/Customer/Packages/Package';

import { PodInterface } from '../components/Pods/Pod';
import Status from './Status';

interface DeliveryTimeInfoInterface {
    date: string;
    estimatedDeliveryTime: string;
    estimatedDeliveryTimeSpan: string;
    timeWindow: string;
    actualDeliveryTime?: string;
    dayForHumans?: string;
    dateForHumans?: string;
}

export interface CustomerInterface {
    anonymized: boolean;
    error: ErrorInterface;
    hasLoadedFromServer: boolean;
    customerId: number;
    customerName: string;
    receivedBy?: string;
    hash?: string;
    isCollect: boolean;
    isDelivery: boolean;
    isPickup: boolean;
    packages: PackageInterface[];
    pickUps: PackageInterface[];
    recycling: PackageInterface[];
    sender: SenderInterface;
    driverLocation: LocationInterface;
    externalId?: string;
    status: Status;
    address: AddressInterface;
    surveyUrl?: string;
    settings: {
        mayCancelDelivery: boolean;
        hasSurvey: boolean;
        showEstimatedDeliveryTime: boolean;
        showTrackingNumber: boolean;
        digitalSignatures: boolean;
        allowChangeUnattendedOk: boolean;
        doIdentityVerification: boolean;
        doAgeVerification: boolean;
        useBankIdQrCode: boolean;
    };
    hasBoxKey: boolean;
    routeIsLocked: boolean;
    hasTheme: boolean;
    hasQrCode: boolean;
    proposeDays: any[];
    refresh: any;
    locale: string;
    customerSupportMarkup?: string;
    ageVerification?: number;
    verificationCode?: string;
    isIdentityVerified?: boolean;
    unattendedOk: boolean;
    mapProviderUrl?: string;
    mapProviderName?: string;
    notes: {
        saveButtonText: string;
        canEditNotes: boolean;
        title: string;
        subTitle: string;
        driverNotes: DriverNoteInterface[];
    };
    pods: PodInterface[];
    deliveryTimeInfo: DeliveryTimeInfoInterface;
    _t: (value: string) => string
}

const params = window.location.pathname.split('/').filter((string) => string);

export const hash = (): string => {
    const hash = params[0];

    if (typeof hash === 'undefined') {
        return '';
    }

    return hash;
};

export const DefaultCustomer: CustomerInterface = {
    anonymized: false,
    error: {
        message: null,
        any: false,
    },
    hasLoadedFromServer: false,
    hash: hash(),
    isCollect: false,
    isDelivery: true,
    isPickup: false,
    customerId: 0,
    customerName: '',
    packages: [],
    pickUps: [],
    recycling: [],
    sender: {
        name: '',
        customerServiceNumber: null,
        customerServiceEmail: null,
        customerService: '',
    },
    driverLocation: {
        lat: 0,
        lng: 0,
    },
    status: Status.defaultStatus(),
    address: {
        description: '',
        lat: 0,
        lng: 0,
    },
    settings: {
        mayCancelDelivery: false,
        hasSurvey: false,
        showEstimatedDeliveryTime: false,
        showTrackingNumber: false,
        digitalSignatures: false,
        allowChangeUnattendedOk: false,
        doAgeVerification: false,
        doIdentityVerification: false,
        useBankIdQrCode: false,
    },
    hasBoxKey: false,
    routeIsLocked: false,
    refresh: () => null,
    locale: 'sv_SE',
    hasTheme: false,
    hasQrCode: false,
    proposeDays: [],
    unattendedOk: true,
    notes: {
        saveButtonText: '',
        canEditNotes: true,
        title: '',
        subTitle: '',
        driverNotes: [],
    },
    pods: [],
    deliveryTimeInfo: {
        date: '',
        dayForHumans: '',
        dateForHumans: '',
        estimatedDeliveryTime: '',
        estimatedDeliveryTimeSpan: '',
        timeWindow: '',
    },
    _t: function (value): string {
        console.error('Translation not implemented!');
        return value;
    }
};
