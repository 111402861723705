import React from 'react';
import axios from 'axios';
import { apiThemeEndpoint } from '../utils/api';
import { ThemeInterface, DefaultTheme } from './ITheme';
import { hash } from './ICustomer';
import Util from '../utils/Util';

export const ThemeContext = React.createContext<ThemeInterface>(DefaultTheme);

export const ThemeConsumer = ThemeContext.Consumer;

export class ThemeProvider extends React.Component<any, ThemeInterface> {
    public constructor(props: any) {
        super(props);
        this.state = DefaultTheme;
        this.fetchData();
    }

    private getTheme = (): any => {
        return axios.get(apiThemeEndpoint(hash()));
    };

    public fetchData = () => {
        if (Util.checkIsNull(hash())) {
            return;
        }

        /*eslint camelcase: [
            "error", {
                properties: "never",
                ignoreDestructuring: true,
                allow: ["customer_pin", "response_data", "has_theme", "truck_pin"]
            }
        ]*/
        this.getTheme()
            .then((resp: any) => {
                if (resp.status !== 200) {
                    return; // if theme is not loaded we do not need to brake the application
                }
                const hasTheme = resp.data.response_data.has_theme && resp.data.response_data.has_theme === true;
                if (hasTheme) {
                    const { logo, theme, customer_pin, truck_pin } = resp.data.response_data;
                    this.setState({
                        hasTheme: hasTheme,
                        logo: logo,
                        colors: {
                            darkColor: theme.dark_color,
                            lightColor: theme.light_color,
                            textColor: theme.text_color,
                        },
                        fontName: Util.getStringOrDefault(theme.font_name) || undefined,
                        fontUrl: Util.getStringOrDefault(theme.font_url) || undefined,
                        customerPin: Util.getStringOrDefault(customer_pin) || undefined,
                        truckPin: Util.getStringOrDefault(truck_pin) || undefined,
                    });
                }
            })
            .catch((error: any) => console.error(error));
    };

    public render(): JSX.Element {
        return <ThemeContext.Provider value={this.state}>{this.props.children}</ThemeContext.Provider>;
    }
}