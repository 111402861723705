import React from 'react';
import axios from 'axios';
import { apiUpdateDriverNoteEndpoint } from '../../../utils/api';
import { CustomerConsumer } from '../../../context/Customer';
import { hash } from '../../../context/ICustomer';
import { Title, Row, Note } from '../../Customer/Layout';
import { DriverNote, DriverNoteInterface } from './DriverNote';
import './driverNotes.scss';
import { SaveButton } from '../../Button/SaveButton';

interface PropsInterface {
    canEditNotes: boolean;
    driverNotes: DriverNoteInterface[];
}

interface StateInterface extends PropsInterface {
    isChanged: boolean;
    canEditNotes: boolean;
}

export default class DriverNotes extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: StateInterface) {
        super(props);
        this.updateNoteState = this.updateNoteState.bind(this);
        this.save = this.save.bind(this);

        const { driverNotes } = props;
        this.state = { driverNotes, isChanged: false, canEditNotes: true };
    }

    private saveDriverNote(driverNotes: DriverNoteInterface[]): void {
        const requestObj = driverNotes.map((note) => {
            const { value, id } = note;
            return { value, id };
        });

        if (requestObj.length < 1) {
            return;
        }

        axios.post(apiUpdateDriverNoteEndpoint(hash()), requestObj).catch((e) => alert(e.message));
    }

    public save(): void {
        this.saveDriverNote(this.state.driverNotes);
        this.setState({ isChanged: false });
    }

    public updateNoteState(id: number, value: string): void {
        const driverNotes = this.state.driverNotes.map((note) => {
            if (note.id !== id) {
                return note;
            }
            if (!this.noteIsValid(note, value)) {
                return note;
            }

            note.value = value;
            return note;
        });

        this.setState({ driverNotes, isChanged: true });
    }

    private noteIsValid(note: DriverNoteInterface, newValue: string): boolean {
        return note.value !== newValue;
    }

    private renderDriverNotes(title: string): JSX.Element[] {
        return this.state.driverNotes.map((note) => {
            return <DriverNote note={note} key={note.id} placeHolder={title} callback={this.updateNoteState} />;
        });
    }

    public render(): JSX.Element | null {
        if (!this.props.canEditNotes) {
            return null;
        }

        return (
            <CustomerConsumer>
                {(customer) => (
                    <Row>
                        <Title title={customer.notes.title} />
                        <div className="driver-note">
                            {this.renderDriverNotes(customer.notes.title)}
                            <div>
                                <Note text={customer.notes.subTitle} />
                            </div>
                            <SaveButton
                                onClickFunction={this.save}
                                show={this.state.isChanged}
                                title={customer.notes.saveButtonText}
                            />
                        </div>
                    </Row>
                )}
            </CustomerConsumer>
        );
    }
}
