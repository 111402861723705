import React, { Component } from 'react';

export default class Anonymized extends Component {
    private getText = (property: number) => {
        const texts = {
            en: [
                'In accordance with EU:s data protection regulation GDPR the information connected to this delivery has been deleted',
            ],
            sv: [
                'I enlighet med EU:s dataskyddsreglering GDPR har informationen kopplad till denna leverans blivit raderad',
            ],
        };

        switch (navigator.language) {
            case 'sv':
                return texts.sv[property];
            case 'en':
            case 'en-GB':
            case 'en-US':
            default:
                return texts.en[property];
        }
    };

    public render(): JSX.Element {
        return (
            <div
                style={{
                    padding: '20px',
                    maxWidth: '500px',
                    margin: '80px auto',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <h2>{this.getText(0)}</h2>
            </div>
        );
    }
}
