import React, { useContext } from 'react';
import * as L from 'leaflet';
import { Marker } from 'react-leaflet';
import { CustomerContext } from '../../../context/Customer';
import { ThemeContext } from '../../../context/Theme';

const CustomerTile = (): JSX.Element => {
    const { lat, lng } = useContext(CustomerContext).address;
    const { customerPin } = useContext(ThemeContext);

    const customerIcon = customerPin
        ? L.icon({
              iconUrl: customerPin,
              iconSize: [64, 78],
              iconAnchor: [32, 78],
          })
        : L.divIcon({ html: '<div class="pin bounce" />', iconSize: [0, 0] });

    return <Marker position={[lat, lng]} icon={customerIcon}></Marker>;
};

export default CustomerTile;
