import React from 'react';
import { CustomerProvider } from './context/Customer';
import { ThemeProvider } from './context/Theme';
import Router from './Router';

const App = (): JSX.Element => {
    return (
        <CustomerProvider>
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </CustomerProvider>
    );
};

export default App;
