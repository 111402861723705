import { getEnv } from "./config";

const apiUrl = (endpoint: string, hash?: string, secondParam?: string): string => {
    const apiBaseUrl = getEnv('REACT_APP_API_BASE_URL');

    if (hash) {
        if (secondParam) {
            return `${apiBaseUrl}/customer/${endpoint}/${hash}/${secondParam}`
        }
        return `${apiBaseUrl}/customer/${endpoint}/${hash}`
    }

    return `${apiBaseUrl}/customer/${endpoint}`;
};

export const apiDeliveryEndpoint = (hash?: string) => apiUrl('delivery', hash);

export const apiCancelOrderEndpoint = (hash?: string) => apiUrl('cancel', hash);

export const apiThemeEndpoint = (hash?: string) => apiUrl('theme', hash);

export const apiUpdateDriverNoteEndpoint = (hash?: string) => apiUrl('updateNote', hash);

export const apiHandleBlobEndpoint = (hash?: string) => apiUrl('handleBlob', hash);

export const apiRemoveBoxKey = (hash?: string) => apiUrl('removeBoxKey', hash);

export const apiUpdateUnattended = (hash?: string) => apiUrl('unattended', hash);

export const googleMapsApiKey = (): string => getEnv('REACT_APP_GOOGLE_MAP_API_KEY');

export const apiStartBankId = (hash?: string) => apiUrl('startBankId', hash);

export const apiBankIdCallback = (hash?: string, orderRef?: string) => apiUrl('bankIdCallback', hash, orderRef);
